import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// UI
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import { enqueueSnackbar } from 'notistack';

// Services
import { getPartners } from 'services/partners';
import { getContracts } from 'services/contracts';

// Components
import TableStaffs from './TableStaffs';
import { IconUserPlus, IconUsers } from '@tabler/icons';

const PartnerStaffs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const [partners, setPartners] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [loading, setLoading] = useState(true);

    const handlePartnerChange = (event) => {
        setLoading(true);
        dispatch({ type: 'SET_PARTNER_SELECTED', payload: event.target.value });
        dispatch({ type: 'SET_CONTRACT_SELECTED', payload: '' });
        getPartnerContracts();
    };

    const handleContractChange = (event) => {
        setLoading(true);
        dispatch({ type: 'SET_CONTRACT_SELECTED', payload: event.target.value });
    };

    const getAllPartners = () => {
        getPartners().then((response) => {
            const { data } = response.data;
            setPartners(data);
        });
    };

    const getPartnerContracts = () => {
        getContracts('', '', '', partnerSelected).then((response) => {
            const { data } = response.data;
            setContracts(data);
        });
    };

    const newStaff = () => {
        if (!contractSelected) {
            return enqueueSnackbar('Selecione um contrato para adicionar novos efetivos', { variant: 'error' });
        }
        navigate({ pathname: '/novo_efetivo' });
    };

    useEffect(() => {
        if (contracts?.length === 1) {
            dispatch({ type: 'SET_CONTRACT_SELECTED', payload: contracts[0].id });
        }
    }, [contracts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (partners?.length === 1) {
            dispatch({ type: 'SET_PARTNER_SELECTED', payload: partners[0].id });
        }
    }, [partners]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getPartnerContracts();
    }, [partnerSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAllPartners();
    }, []);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUsers />
                    <Typography variant="h2" component="div">
                        Efetivos
                    </Typography>
                </Stack>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="partnerSelectLabel" shrink>
                            Selecione o parceiro
                        </InputLabel>
                        <Select
                            labelId="partnerSelectLabel"
                            label="Selecione o parceiro"
                            id="partnerSelect"
                            name="partnerSelect"
                            value={partnerSelected}
                            onChange={handlePartnerChange}
                        >
                            {partners?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nome_fantasia}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="contractSelectLabel" shrink>
                            Selecione o contrato
                        </InputLabel>
                        <Select
                            labelId="contractSelectLabel"
                            label="Selecione o contrato"
                            id="contractSelect"
                            name="contractSelect"
                            value={contractSelected}
                            onChange={handleContractChange}
                        >
                            {contracts?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.numero} ({item.empresa})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <TableStaffs loading={loading} setLoading={setLoading} />
            <SpeedDial direction="left" ariaLabel="Efetivos" sx={{ position: 'absolute', bottom: 32, right: 32 }} icon={<AddIcon />}>
                <SpeedDialAction icon={<IconUserPlus />} tooltipTitle="Novo Efetivo" onClick={newStaff} />
            </SpeedDial>
        </MainCard>
    );
};

export default PartnerStaffs;
