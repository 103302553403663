import admin from './admin';
import partner from './partner';
import partnerDocuments from './partnerDocuments';
import staffDocuments from './staffDocuments';
import adminDocumentStatus from './adminDocumentStatus';
import partnerDocumentStatus from './partnerDocumentStatus';

const partnerItems = {
    items: [partner, partnerDocumentStatus, partnerDocuments, staffDocuments]
};

const menuItems = {
    items: [admin, adminDocumentStatus, partnerDocuments, staffDocuments]
};

export { partnerItems, menuItems };
