import { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    CircularProgress,
    Grid,
    Stack
} from '@mui/material';
import { Visibility, AttachFile, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import { getTypeDocuments } from 'services/contracts';
import ErrorIcon from '@mui/icons-material/Error';
import UploadDocumentFiles from 'components/UploadDocumentFiles';
import DocumentFiles from 'components/DocumentFiles';
import DocumentStatus from 'components/DocumentStatus';
import moment from 'moment';
import DocumentNotification from 'components/DocumentNotification';
import { IconMessage2 } from '@tabler/icons';
import CircleErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'prop-types';

const TableDocuments = ({ contractSelected }) => {
    const [openValidate, setOpenValidate] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const [allDocuments, setAllDocuments] = useState([]);

    const [documentSelected, setDocumentSelected] = useState(null);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState(null);

    const [loading, setLoading] = useState(false);

    const getDocuments = () => {
        const group_documents = 11;
        setLoading(true);
        getTypeDocuments(group_documents, contractSelected).then((resp) => {
            setAllDocuments(resp.data);
            setLoading(false);
        });
    };

    const renderStatus = (status) => {
        if (status === 'A VALIDAR') {
            return <Chip sx={{ width: 100 }} color="warning" label="A validar" />;
        }
        if (status === 'VALIDADO') {
            return <Chip sx={{ width: 100 }} color="success" label="Validado" />;
        }
        return <Chip sx={{ width: 100 }} color="error" label="Pendente" />;
    };

    const renderValidade = (validade) => {
        if (validade) {
            return <Chip sx={{ width: 110 }} color="primary" label={moment(validade).format('DD/MM/YYYY')} />;
        }
        return <Chip sx={{ width: 110 }} label="Sem validade" />;
    };

    useEffect(() => {
        if (contractSelected) {
            getDocuments();
        }
    }, [contractSelected]); // eslint-disable-line

    return (
        <Stack sx={{ pb: 2 }}>
            {allDocuments.length > 0 ? (
                <>
                    <TableContainer component={Paper} sx={{ position: 'relative' }}>
                        {loading && (
                            <Grid
                                container
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 100,
                                    top: 0,
                                    left: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documentos</TableCell>
                                    <TableCell width={150}>Status</TableCell>
                                    <TableCell width={150}>Validade</TableCell>
                                    <TableCell width={100}>Observações</TableCell>
                                    <TableCell width={100}>Visualizar</TableCell>
                                    <TableCell width={100}>Anexar</TableCell>
                                    <TableCell width={100}>Editar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allDocuments?.map((document) => (
                                    <TableRow key={document.id}>
                                        <TableCell>
                                            <Typography variant="p">{document.nome}</Typography>
                                            {document.especificacoes && (
                                                <Typography variant="subtitle2" color="GrayText">
                                                    {document.especificacoes}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>{renderStatus(document.documento?.status)}</TableCell>
                                        <TableCell>{renderValidade(document.documento?.validade)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={document.documento === null}
                                                onClick={() => {
                                                    setTypeDocumentSelected(document);
                                                    setDocumentSelected(document.documento);
                                                    setOpenNotificationModal(true);
                                                }}
                                                sx={{ position: 'relative' }}
                                            >
                                                <IconMessage2 />
                                                {document.documento?.novas_notificacoes == 1 && (
                                                    <CircleErrorIcon
                                                        color="error"
                                                        sx={{ position: 'absolute', top: 0, left: 0, fontSize: '15px' }}
                                                        //
                                                    />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={document.documento === null}
                                                onClick={() => {
                                                    setTypeDocumentSelected(document);
                                                    setDocumentSelected(document.documento);
                                                    setOpenFilesModal(true);
                                                }}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex' }}>
                                                <IconButton
                                                    disabled={
                                                        document?.documento?.status == 'VALIDADO' &&
                                                        new Date(document?.documento?.validade) > new Date()
                                                    }
                                                    onClick={() => {
                                                        setTypeDocumentSelected(document);
                                                        setDocumentSelected(document.documento);
                                                        setOpenUploadModal(true);
                                                    }}
                                                >
                                                    <AttachFile />
                                                </IconButton>
                                                {document?.documento && (
                                                    <CheckCircleIcon
                                                        sx={{ color: 'green', width: '15px', marginRight: '5px', position: 'absolute' }}
                                                    />
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={document.documento === null}
                                                onClick={() => {
                                                    setDocumentSelected(document.documento);
                                                    setOpenValidate(true);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {allDocuments.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                            Nenhum documento encontrado
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DocumentStatus
                        onOpen={openValidate}
                        onClose={() => setOpenValidate(false)}
                        document={documentSelected}
                        reloadDocuments={() => {
                            getDocuments();
                        }}
                    />
                    <DocumentFiles
                        openModal={openFilesModal}
                        handleCloseModal={() => {
                            setOpenFilesModal(false);
                            setDocumentSelected(null);
                        }}
                        setSuccess={() => {}}
                        documentSelected={documentSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        reloadDocuments={() => {
                            getDocuments();
                        }}
                    />
                    <UploadDocumentFiles
                        openModal={openUploadModal}
                        handleCloseModal={() => {
                            setOpenUploadModal(false);
                            setDocumentSelected(null);
                        }}
                        accept={['application/pdf']}
                        setSuccess={() => {}}
                        contractSelected={contractSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        reloadDocuments={() => {
                            getDocuments();
                        }}
                    />
                    <DocumentNotification
                        open={openNotificationModal}
                        onClose={() => {
                            setOpenNotificationModal(false);
                            getDocuments();
                        }}
                        document={documentSelected}
                    />
                </>
            ) : contractSelected === null || contractSelected === '' ? (
                <Typography variant="h4" display="flex" justifyContent="center" alignItems="center" sx={{ py: 2, color: 'GrayText' }}>
                    Selecione um contrato
                    <ErrorIcon color="warning" />
                </Typography>
            ) : (
                <Typography variant="h4" display="flex" justifyContent="center" alignItems="center" sx={{ py: 2, color: 'GrayText' }}>
                    Nenhum documento encontrado
                </Typography>
            )}
        </Stack>
    );
};

TableDocuments.propTypes = {
    contractSelected: PropTypes.number
};

export default TableDocuments;
