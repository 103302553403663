export const initialState = {
    partnerSelected: '',
    contractSelected: '',
    page: 0,
    rowsPerPage: 10,
    filter: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const staffsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PARTNER_SELECTED':
            return {
                ...state,
                partnerSelected: actions.payload
            };
        case 'SET_CONTRACT_SELECTED':
            return {
                ...state,
                contractSelected: actions.payload
            };
        case 'SET_PAGE_STAFF':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_STAFF':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_FILTER_STAFF':
            return {
                ...state,
                filter: actions.payload
            };
        case 'SET_CLEAR_FILTER_STAFF':
            return {
                ...state,
                page: 1,
                rowsPerPage: 10,
                filter: ''
            };
        default:
            return state;
    }
};

export default staffsReducer;
