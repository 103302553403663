import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// material-ui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// third-party
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// chart data
import chartData from './chart-data/total-growth-bar-chart';
import { useNavigate } from 'react-router';
import { Stack } from '@mui/material';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const TotalGrowthBarChart = ({ isLoading, data }) => {
    const [chart, setChart] = React.useState(chartData);

    const navigate = useNavigate();

    const documentsTotal = () => {
        if (!data?.total) return 0;
        return data?.total?.documento?.pendente + data?.total?.documento?.validar + data?.total?.documento?.validado;
    };

    useEffect(() => {
        const pendings = parseInt(data?.total?.documento?.pendente);
        const toValidate = parseInt(data?.total?.documento?.validar);
        const validated = parseInt(data?.total?.documento?.validado);

        setChart({
            ...chart,
            options: {
                ...chart.options,
                chart: {
                    ...chart.options.chart,
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            const index = config.dataPointIndex;
                            const routes = [
                                //
                                '/documentos-pendentes',
                                '/documentos-a-validar'
                            ];
                            navigate(routes[index]);
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        ...chart.options.plotOptions.bar,
                        columnWidth: '30%',
                        distributed: true
                    }
                },
                xaxis: {
                    type: 'category',
                    categories: ['Pendentes', 'A validar', 'Validados']
                },
                colors: ['#f44336', '#ffc107', '#00c853']
            },
            series: [
                {
                    name: 'Documentos',
                    data: [pendings, toValidate, validated]
                }
            ]
        });
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Stack direction="row" spacing={1} alignItems={'center'}>
                                        <Typography variant="h3">Documentos</Typography>
                                        <Typography variant="subtitle1">(Total: {documentsTotal()})</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                '& .apexcharts-menu.apexcharts-menu-open': {
                                    bgcolor: 'background.paper'
                                }
                            }}
                        >
                            <Chart {...chart} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

TotalGrowthBarChart.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object
};

export default TotalGrowthBarChart;
