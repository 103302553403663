import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';

// ==============================|| PROFILE MENU ||============================== //

ListItemButton.defaultProps = {
    ...ListItemButton.defaultProps,
    sx: {
        borderRadius: '5px',
        ':hover': {
            bgcolor: '#efefef',
            color: '#FFF',
            '.MuiListItemIcon-root': {
                color: '#1E88E5'
            },
            '.MuiTypography-root': {
                color: '#1E88E5'
            }
        }
    }
};

const roles = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Parceiro' },
    { id: 3, name: 'Gestor de contratos' }
];

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const user = useSelector((state) => state.auth.user);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        localStorage.removeItem('token');
        navigate({ pathname: '/login' });
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    backgroundColor: '#dadada',
                    color: theme.palette.primary.main,
                    borderColor: '#9a9a9a',
                    '& svg': {
                        stroke: '#666'
                    },
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        background: `#9a9a9a !important`,
                        borderColor: '#999',
                        color: theme.palette.primary.main,
                        '& svg': {
                            stroke: 'white'
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            bgcolor: '#FFF'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        aria-label="recipe"
                    >
                        {user?.name?.length > 0 ? user?.name[0] : 'R'}
                    </Avatar>
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2, pb: 0 }}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Avatar
                                                sx={{
                                                    ...theme.typography.mediumAvatar,
                                                    cursor: 'pointer',
                                                    bgcolor: '#ededed'
                                                }}
                                            >
                                                {user?.name.length > 0 ? user?.name[0] : 'R'}
                                            </Avatar>
                                            <Box>
                                                <Typography variant="h5">{user?.name}</Typography>
                                                <Typography variant="subtitle2" sx={{ color: theme.palette.grey[500] }}>
                                                    {roles.find((role) => role.id === user?.perfil_id).name}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Divider sx={{ mt: 1 }} />
                                    </Box>
                                    <PerfectScrollbar>
                                        <Box sx={{ p: 1 }}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    pt: 0,
                                                    width: 300,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton selected={selectedIndex === 1} onClick={() => navigate('/perfil')}>
                                                    <ListItemIcon>
                                                        <IconUser stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Perfil</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
