import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CloseIcon from '@mui/icons-material/Close';
import { deleteVacation, getVacations } from 'services/vacation';
import moment from 'moment';
import AddVacation from '../AddVacation';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const VacationStaff = ({ open, onClose, staff }) => {
    const [vacations, setVacations] = useState([]);

    const [formOpen, setFormOpen] = useState(false);

    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const handleClose = () => {
        onClose();
    };

    const fetchVacations = () => {
        getVacations(contractSelected, staff?.id).then((response) => {
            const { data } = response.data;
            setVacations(data);
        });
    };

    const removeVacation = (vacation) => {
        deleteVacation(vacation.id)
            .then(() => {
                enqueueSnackbar('Período de férias removido com sucesso', { variant: 'success' });
                fetchVacations();
            })
            .catch((error) => {
                console.error(error.response);
                enqueueSnackbar(error.response.data?.error || 'Erro ao remover férias', { variant: 'error' });
            });
    };

    useEffect(() => {
        if (open) {
            fetchVacations();
        }
    }, [open]); // eslint-disable-line

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    minWidth: 400,
                    maxWidth: 600,
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 4
                }}
            >
                <Card>
                    <CardHeader
                        title={
                            <Stack direction="row" spacing={1}>
                                <Box>Férias de {staff?.nome}</Box>
                            </Stack>
                        }
                        action={
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        sx={{ p: 2 }}
                    />
                    <CardContent sx={{ py: 1, px: 2 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Início</TableCell>
                                        <TableCell>Fim</TableCell>
                                        <TableCell>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vacations.map((vacation) => (
                                        <TableRow key={vacation.id}>
                                            <TableCell>{moment(vacation.inicio).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell>{moment(vacation.fim).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1}>
                                                    <ConfirmationDialog
                                                        title="Atenção!"
                                                        description={`Você tem certeza que deseja remover este período de férias?`}
                                                        response={() => removeVacation(vacation)}
                                                        severity="warning"
                                                        confirmationText="Sim, prosseguir"
                                                    >
                                                        {(showDialog) => (
                                                            <Tooltip title="Remover férias" arrow>
                                                                <IconButton onClick={showDialog}>
                                                                    <EventBusyIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </ConfirmationDialog>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {!vacations.length && (
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>
                                                Nenhum período de férias cadastrado
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <CardActions sx={{ p: 2 }}>
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" color="primary" onClick={() => setFormOpen(true)}>
                                Adicionar
                            </Button>
                            <Button variant="outlined" color="primary" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Stack>
                    </CardActions>
                </Card>
                <AddVacation
                    open={formOpen}
                    onClose={() => {
                        setFormOpen(false);
                        fetchVacations();
                    }}
                    staff={staff}
                />
            </Box>
        </Modal>
    );
};

VacationStaff.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    staff: PropTypes.object.isRequired
};

export default VacationStaff;
