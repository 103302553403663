import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { deleteNotification, getNotification, postNotification } from 'services/documentNotifications';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconChecks, IconCirclePlus, IconTrash } from '@tabler/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import PropTypes from 'prop-types';

const DocumentNotification = ({ open, onClose, document }) => {
    const user = useSelector((state) => state.auth.user);

    const [notifications, setNotifications] = useState([]);

    const [loading, setLoading] = useState(false);

    const fetchNotifications = () => {
        setLoading(true);
        getNotification(document.id)
            .then((resp) => {
                setNotifications(resp.data);
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSend = (values) => {
        values.documento_id = document.id;
        values.user_id = user.id;
        setLoading(true);
        postNotification(values)
            .then(() => {
                fetchNotifications();
                sendMessageFormik.setFieldValue('mensagem', '');
            })
            .catch((e) => {
                enqueueSnackbar('Erro ao enviar observação', { variant: 'error' });
                console.error(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        setLoading(true);
        deleteNotification(id)
            .then(() => {
                fetchNotifications();
                enqueueSnackbar('Observação deletada com sucesso', { variant: 'success' });
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const handleClose = () => {
        onClose();
    };

    const sendMessageFormik = useFormik({
        initialValues: {
            mensagem: ''
        },
        onSubmit: handleSend
    });

    const canDelete = (notification) => {
        if (user.perfil_id == 1 || user.perfil_id == 3) return true;
        return notification.user_id == user.id && notification.lido == false;
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [notifications]);

    useEffect(() => {
        if (open && document) {
            fetchNotifications();
        }
    }, [open]); // eslint-disable-line

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '20px'
                }}
            >
                <Card>
                    <CardHeader
                        title={
                            <Stack direction="row" spacing={1}>
                                <Box>Observações</Box>
                            </Stack>
                        }
                        action={
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        sx={{ p: 2 }}
                    />
                    <CardContent sx={{ pt: 1 }}>
                        <Paper sx={{ mb: 2, pr: 1, maxHeight: 480, overflow: 'auto', position: 'relative' }}>
                            {loading && (
                                <Grid
                                    container
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 100,
                                        top: 0,
                                        left: 0,
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CircularProgress />
                                </Grid>
                            )}
                            {notifications.map((notification) => (
                                <Stack
                                    direction="column"
                                    spacing={0.5}
                                    key={notification.id}
                                    sx={{
                                        mb: 2,
                                        p: 1.5,
                                        backgroundColor: notification.user_id == user.id ? '#f5f5f5' : 'primary.light',
                                        borderRadius: 2
                                    }}
                                >
                                    <Stack direction="row" spacing={1} justifyContent={'space-between'}>
                                        <Typography variant="h6">{notification.user_name}</Typography>
                                        <Stack direction="row" spacing={1}>
                                            {notification.lido == 1 && <IconChecks size={18} color="#bbb" />}
                                            {canDelete(notification) && (
                                                <ConfirmationDialog
                                                    title="Deletar observação"
                                                    description="Deseja realmente deletar essa observação?"
                                                    response={() => handleDelete(notification.id)}
                                                    severity="error"
                                                >
                                                    {(openDialog) => (
                                                        <IconButton
                                                            sx={{ p: 0 }}
                                                            size="small"
                                                            onClick={openDialog}
                                                            disabled={!canDelete(notification)}
                                                        >
                                                            <IconTrash size={16} color="#bbb" />
                                                        </IconButton>
                                                    )}
                                                </ConfirmationDialog>
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Typography variant="body2">{notification.mensagem}</Typography>
                                    <Typography variant="caption">
                                        {moment(notification.created_at).format('DD/MM/YYYY - HH:mm')}
                                    </Typography>
                                </Stack>
                            ))}
                            {notifications.length === 0 && (
                                <Stack direction="row" spacing={1} justifyContent={'center'} sx={{ p: 2 }}>
                                    <Typography variant="body2">Nenhuma observação</Typography>
                                </Stack>
                            )}
                            <div ref={messagesEndRef} />
                        </Paper>
                        <Divider sx={{ my: 2 }} />
                        <form onSubmit={sendMessageFormik.handleSubmit}>
                            <Stack display="flex" direction="row" spacing={1}>
                                <FormControl sx={{ flexGrow: 1 }}>
                                    <TextField
                                        id="mensagem"
                                        label="Adicionar observação"
                                        variant="outlined"
                                        value={sendMessageFormik.values.mensagem}
                                        onChange={sendMessageFormik.handleChange}
                                    />
                                </FormControl>
                                <IconButton type="submit" size="large" color="success">
                                    <IconCirclePlus />
                                </IconButton>
                            </Stack>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
};

DocumentNotification.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    document: PropTypes.object
};

export default DocumentNotification;
