import {
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { IconFileCheck, IconFileExport, IconMenu2, IconMessage2 } from '@tabler/icons';
import { getValidateDocs } from 'services/documents';
import { useEffect, useState } from 'react';
import { EditOutlined, Search, Visibility } from '@mui/icons-material';
import DocumentFiles from 'components/DocumentFiles';
import DocumentStatus from 'components/DocumentStatus';
import { useDebouncedCallback } from 'use-debounce';
import { CSVLink } from 'react-csv';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import moment from 'moment';
import DocumentNotification from 'components/DocumentNotification';

const DocsToValidate = () => {
    const [documentos, setDocumentos] = useState([]);

    const [documentoSelected, setDocumentoSelected] = useState(null);

    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const fetchDocumentos = useDebouncedCallback(() => {
        getValidateDocs(search, page + 1, rowsPerPage).then((response) => {
            setDocumentos(response.data);
            setLoading(false);
        });
    }, 1000);

    const renderStatus = (status) => {
        switch (status) {
            case 'A VALIDAR':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="warning" label="A validar" />;
            case 'VALIDADO':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="success" label="Validado" />;
            case 'PENDENTE':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="error" label="Pendente" />;
            default:
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="error" label="Pendente" />;
        }
    };

    const renderMonthly = (monthly, period) => {
        if (monthly) {
            const date = period ? moment(period).format('MM/YYYY') : moment().format('MM/YYYY');
            return <Chip style={{ width: '100px', textAlign: 'center' }} color="primary" label={date} />;
        }
        return <Chip style={{ width: '100px', textAlign: 'center' }} label="Não" />;
    };

    const getCSVData = () => {
        if (documentos?.data) {
            return documentos?.data?.map((doc) => {
                return {
                    empresa: doc.empresa,
                    nome_fantasia: doc.nome_fantasia,
                    contrato: doc.contrato,
                    efetivo: doc.efetivo,
                    grupo_doc: doc.grupo_doc,
                    tipo_doc: doc.tipo_doc,
                    mensal: doc.mensal,
                    status: doc.status
                };
            });
        }
        return [];
    };

    useEffect(() => {
        fetchDocumentos();
    }, []); // eslint-disable-line

    useEffect(() => {
        setLoading(true);
        fetchDocumentos();
    }, [search, page, rowsPerPage]); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconFileCheck />
                    <Typography variant="h2" component="div">
                        Documentos a validar
                    </Typography>
                </Stack>
            }
        >
            <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Pesquisar"
                        variant="filled"
                        size="small"
                        InputProps={{
                            endAdornment: <Search />
                        }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                </FormControl>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <IconButton {...bindTrigger(popupState)}>
                                <IconMenu2 width={29} />
                            </IconButton>
                            <Menu
                                {...bindMenu(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                sx={{ marginTop: 1 }}
                            >
                                <CSVLink data={getCSVData()} filename="validar.csv" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <MenuItem>
                                        <IconFileExport color="#00c853" style={{ marginRight: 4 }} />
                                        Download CSV
                                    </MenuItem>
                                </CSVLink>
                            </Menu>
                        </>
                    )}
                </PopupState>
            </Stack>
            <TableContainer sx={{ borderRadius: '10px', position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Table>
                    <TableHead sx={{ backgroundColor: '#DCDCDC' }}>
                        <TableRow>
                            <TableCell>Tipo de Documento</TableCell>
                            <TableCell>Parceiro</TableCell>
                            <TableCell>Efetivo</TableCell>
                            <TableCell>Contrato</TableCell>
                            <TableCell>Empresa</TableCell>
                            <TableCell>Mensal/Período</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Obs.</TableCell>
                            <TableCell>Vis.</TableCell>
                            <TableCell>Editar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentos?.data?.map((documento) => (
                            <TableRow key={documento.id}>
                                <TableCell>
                                    <Typography variant="p">{documento.tipo_doc}</Typography>
                                    {documento.tipo_doc_especificacoes && (
                                        <Typography variant="subtitle2" color="GrayText">
                                            {documento.tipo_doc_especificacoes}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>{documento.nome_fantasia}</TableCell>
                                <TableCell>{documento.efetivo}</TableCell>
                                <TableCell>{documento.contrato}</TableCell>
                                <TableCell>{documento.empresa}</TableCell>
                                <TableCell>{renderMonthly(documento.mensal, documento.periodo)}</TableCell>
                                <TableCell>{renderStatus(documento.status)}</TableCell>
                                <TableCell>
                                    <IconButton
                                        disabled={document.documento === null}
                                        onClick={() => {
                                            setDocumentoSelected({ id: documento.id });
                                            setOpenNotificationModal(true);
                                        }}
                                        sx={{ position: 'relative' }}
                                    >
                                        <IconMessage2 />
                                        {document.documento?.novas_notificacoes == 1 && (
                                            <CircleErrorIcon
                                                color="error"
                                                sx={{ position: 'absolute', top: 0, left: 0, fontSize: '15px' }}
                                            />
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            setDocumentoSelected(documento);
                                            setOpenFilesModal(true);
                                        }}
                                    >
                                        <Visibility />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            setDocumentoSelected(documento);
                                            setOpenEditModal(true);
                                        }}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {documentos?.data?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    Nenhum documento encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={10}
                                count={documentos.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                onPageChange={(e, newPage) => setPage(newPage)}
                                onRowsPerPageChange={(e) => {
                                    setPage(0);
                                    setRowsPerPage(e.target.value);
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <DocumentFiles
                openModal={openFilesModal}
                handleCloseModal={() => setOpenFilesModal(false)}
                documentSelected={documentoSelected}
                typeDocumentSelected={{
                    id: documentoSelected?.tipo_doc_id,
                    nome: documentoSelected?.tipo_doc
                }}
                reloadDocuments={() => {
                    fetchDocumentos();
                }}
            />
            <DocumentStatus
                onOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
                document={documentoSelected}
                reloadDocuments={() => {
                    fetchDocumentos();
                }}
            />
            <DocumentNotification
                open={openNotificationModal}
                onClose={() => {
                    setOpenNotificationModal(false);
                    fetchDocumentos();
                }}
                document={documentoSelected}
            />
        </MainCard>
    );
};

export default DocsToValidate;
