import { useState, useEffect } from 'react';
import {
    // Material-UI
    Grid,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Stack,
    Typography,
    Divider
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { getPartners } from 'services/partners';
import { getContracts } from 'services/contracts';
import TableDocuments from './components/TableDocuments';
import { ptBR } from 'date-fns/locale';
import { FolderCopyOutlined } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const DocsStaffsMonthly = () => {
    const [partners, setPartners] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [partnerSelected, setPartnerSelected] = useState('');
    const [contractSelected, setContractSelected] = useState('');
    const [selectedDate, setSelectedDate] = useState(moment());

    const getAllPartners = () => {
        getPartners().then((resp) => {
            setPartners(resp.data.data);
            if (resp.data.data.length === 1) setPartnerSelected(resp.data.data[0].id);
        });
    };

    const getAllContracts = () => {
        getContracts('', '', '', partnerSelected, '').then((resp) => {
            setContracts(resp.data.data);
            if (resp.data.data.length === 1) setContractSelected(resp.data.data[0].id);
        });
    };

    useEffect(() => {
        getAllPartners();
    }, []);

    useEffect(() => {
        if (partnerSelected) {
            getAllContracts();
            setContractSelected('');
        }
    }, [partnerSelected]); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <FolderCopyOutlined />
                    <Typography variant="h2" component="div">
                        Efetivo - Documentos mensais
                    </Typography>
                </Stack>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="partner" shrink={true}>
                            Parceiro
                        </InputLabel>
                        <Select
                            labelId="partner"
                            id="partner"
                            value={partnerSelected}
                            label="Parceiro"
                            onChange={(e) => setPartnerSelected(e.target.value)}
                        >
                            {partners.map((partner) => (
                                <MenuItem key={partner.id} value={partner.id}>
                                    {partner.nome_fantasia}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="contract" shrink={true}>
                            Contrato
                        </InputLabel>
                        <Select
                            labelId="contract"
                            id="contract"
                            value={contractSelected}
                            label="Contrato"
                            onChange={(e) => setContractSelected(e.target.value)}
                        >
                            {contracts.map((contract) => (
                                <MenuItem key={contract.id} value={contract.id}>
                                    {contract.numero}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <FormControl fullWidth>
                            <DatePicker
                                views={['year', 'month']}
                                label="Mês e Ano"
                                value={selectedDate}
                                onChange={(event) => setSelectedDate(moment(event))}
                                disabled={!contractSelected}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
            <TableDocuments contractSelected={contractSelected || 0} selectedDate={selectedDate || moment()} />
        </MainCard>
    );
};

export default DocsStaffsMonthly;
