import styled from 'styled-components';

import { Button } from '@mui/material';

export const ButtonsGroupStyled = styled.div`
    width: 100%;
`;

export const ButtonStyled = styled(Button)`
    padding: 0.5rem;
    border-radius: 0.2rem;
`;

export const Container = styled.div`
    width: 100%;
    background-color: blue;
`;

export const Row = styled.div`
    width: 100%;
    background-color: red;
`;
