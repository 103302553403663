import { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    Stack,
    CircularProgress,
    Grid
} from '@mui/material';
import { Visibility, AttachFile, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import { getTypeDocumentsContracts } from 'services/contracts';
import ErrorIcon from '@mui/icons-material/Error';
import UploadDocumentFiles from 'components/UploadDocumentFiles';
import DocumentFiles from 'components/DocumentFiles';
import DocumentStatus from 'components/DocumentStatus';
import DocumentNotification from 'components/DocumentNotification';
import { IconMessage2 } from '@tabler/icons';
import CircleErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const TableDocuments = ({ contractSelected, selectedDate }) => {
    const user = useSelector((state) => state.auth.user);

    const [openValidate, setOpenValidate] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const [allDocuments, setAllDocuments] = useState([]);
    const [documentSelected, setDocumentSelected] = useState(null);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState(null);

    const [loading, setLoading] = useState(false);

    function getTypesDocuments() {
        const formattedDate = selectedDate ? selectedDate.format('yyyy-MM') : '';
        setLoading(true);
        getTypeDocumentsContracts(2, contractSelected, '', formattedDate).then((resp) => {
            setAllDocuments([...resp.data]);
            setLoading(false);
        });
    }

    const renderStatus = (status) => {
        if (status === 'A VALIDAR') {
            return <Chip sx={{ width: 100 }} color="warning" label="A validar" />;
        }
        if (status === 'VALIDADO') {
            return <Chip sx={{ width: 100 }} color="success" label="Validado" />;
        }
        return <Chip sx={{ width: 100 }} color="error" label="Pendente" />;
    };

    useEffect(() => {
        if (contractSelected && selectedDate) {
            getTypesDocuments();
        }
    }, [contractSelected, selectedDate]); // eslint-disable-line

    return (
        <Stack sx={{ pb: 2 }}>
            {allDocuments.length > 0 ? (
                <>
                    <TableContainer component={Paper} sx={{ position: 'relative' }}>
                        {loading && (
                            <Grid
                                container
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 100,
                                    top: 0,
                                    left: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documentos</TableCell>
                                    <TableCell width={150}>Status</TableCell>
                                    <TableCell width={100}>Observações</TableCell>
                                    <TableCell width={100}>Visualizar</TableCell>
                                    <TableCell width={100}>Anexar</TableCell>
                                    <TableCell width={100}>Editar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allDocuments?.map((document) => (
                                    <TableRow key={document.id}>
                                        <TableCell>
                                            <Typography variant="p">{document.nome}</Typography>
                                            {document.especificacoes && (
                                                <Typography variant="subtitle2" color="GrayText">
                                                    {document.especificacoes}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>{renderStatus(document.documento?.status)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={document.documento === null}
                                                onClick={() => {
                                                    setTypeDocumentSelected(document);
                                                    setDocumentSelected(document.documento);
                                                    setOpenNotificationModal(true);
                                                }}
                                                sx={{ position: 'relative' }}
                                            >
                                                <IconMessage2 />
                                                {document.documento?.novas_notificacoes == 1 && (
                                                    <CircleErrorIcon
                                                        color="error"
                                                        sx={{ position: 'absolute', top: 0, left: 0, fontSize: '15px' }}
                                                        //
                                                    />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={document.documento === null}
                                                onClick={() => {
                                                    setTypeDocumentSelected(document);
                                                    setDocumentSelected(document.documento);
                                                    setOpenFilesModal(true);
                                                }}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', position: 'relative' }}>
                                                <IconButton
                                                    disabled={
                                                        document?.documento?.status == 'VALIDADO' &&
                                                        new Date(document?.documento?.validade) > new Date()
                                                    }
                                                    onClick={() => {
                                                        setTypeDocumentSelected(document);
                                                        setDocumentSelected(document.documento);
                                                        setOpenUploadModal(true);
                                                    }}
                                                >
                                                    <AttachFile />
                                                </IconButton>
                                                {document.documento && (
                                                    <CheckCircleIcon
                                                        sx={{
                                                            color: 'green',
                                                            width: '15px',
                                                            marginRight: '5px',
                                                            position: 'absolute'
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={document.documento === null || user.perfil_id === 2}
                                                onClick={() => {
                                                    setDocumentSelected(document.documento);
                                                    setOpenValidate(true);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DocumentStatus
                        onOpen={openValidate}
                        onClose={() => setOpenValidate(false)}
                        document={documentSelected}
                        reloadDocuments={() => {
                            getTypesDocuments();
                        }}
                    />
                    <DocumentFiles
                        openModal={openFilesModal}
                        handleCloseModal={() => {
                            setOpenFilesModal(false);
                            setDocumentSelected(null);
                        }}
                        documentSelected={documentSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        reloadDocuments={() => {
                            getTypesDocuments();
                        }}
                    />
                    <UploadDocumentFiles
                        openModal={openUploadModal}
                        handleCloseModal={() => {
                            setOpenUploadModal(false);
                            setDocumentSelected(null);
                        }}
                        accept={['application/pdf']}
                        contractSelected={contractSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        dateSelected={selectedDate}
                        reloadDocuments={() => {
                            getTypesDocuments();
                        }}
                    />
                    <DocumentNotification
                        open={openNotificationModal}
                        onClose={() => {
                            setOpenNotificationModal(false);
                            getTypesDocuments();
                        }}
                        document={documentSelected}
                    />
                </>
            ) : contractSelected === null || contractSelected === '' ? (
                <Typography variant="h4" display="flex" justifyContent="center" alignItems="center" sx={{ py: 2, color: 'GrayText' }}>
                    Selecione um contrato
                    <ErrorIcon color="warning" />
                </Typography>
            ) : (
                <Typography variant="h4" display="flex" justifyContent="center" alignItems="center" sx={{ py: 2, color: 'GrayText' }}>
                    Nenhum documento encontrado
                </Typography>
            )}
        </Stack>
    );
};

TableDocuments.propTypes = {
    contractSelected: PropTypes.number,
    selectedDate: PropTypes.object
};

export default TableDocuments;
