import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { deletePartnerUser, getPartnerUsers } from 'services/partners';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPartnerUser from './AddPartnerUser';
import { IconUserPlus } from '@tabler/icons';
import { enqueueSnackbar } from 'notistack';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import PropTypes from 'prop-types';

const PartnerUsers = ({ open, onClose, partner }) => {
    const [partnerUsers, setPartnerUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addUserOpen, setAddUserOpen] = useState(false);

    const fetchPartnerUsers = () => {
        setLoading(true);
        getPartnerUsers(partner.id)
            .then((resp) => setPartnerUsers(resp.data?.data || []))
            .finally(() => setLoading(false));
    };

    const hendleRemoveUser = (user) => {
        setLoading(true);
        deletePartnerUser(partner.id, user.id)
            .then(() => {
                enqueueSnackbar('Usuário removido com sucesso', { variant: 'success' });
                fetchPartnerUsers();
            })
            .catch((error) => {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                console.error(error.response.data);
            })
            .finally(() => setLoading(false));
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (open) fetchPartnerUsers();
    }, [open]); // eslint-disable-line

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 520,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '20px'
                }}
            >
                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h4" component="div">
                                Usuários permitidos
                            </Typography>
                        }
                        action={
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        sx={{ p: 2 }}
                    />
                    <CardContent sx={{ py: 1, px: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography variant="body2">Parceiro:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h5">{partner.nome_fantasia}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2">Responsável:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h5">{partner.user_name}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <TableContainer
                            component={Paper}
                            sx={{ position: 'relative', minHeight: 280, maxHeight: 340, overflow: 'auto' }}
                            //
                        >
                            {loading && (
                                <Grid
                                    container
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 100,
                                        top: 0,
                                        left: 0,
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CircularProgress />
                                </Grid>
                            )}
                            <Table sx={{ borderRadius: 4 }}>
                                <TableHead sx={{ backgroundColor: '#DCDCDC' }}>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partnerUsers?.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell align="right">
                                                <ConfirmationDialog
                                                    title="Remover Usuário"
                                                    description={`Deseja realmente remover o usuário ${user.name}?`}
                                                    response={() => hendleRemoveUser(user)}
                                                    severity="error"
                                                    confirmationText="Sim, prosseguir"
                                                >
                                                    {(showDialog) => (
                                                        <IconButton onClick={showDialog}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </ConfirmationDialog>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {partnerUsers?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography variant="body2">Nenhum usuário com permissão</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => setAddUserOpen(true)}
                                startIcon={<IconUserPlus size={20} />}
                            >
                                Adicionar
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
                <AddPartnerUser
                    open={addUserOpen}
                    onClose={() => setAddUserOpen(false)}
                    partner={partner}
                    onSuccess={() => fetchPartnerUsers()}
                />
            </Box>
        </Modal>
    );
};

PartnerUsers.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    partner: PropTypes.object
};

export default PartnerUsers;
