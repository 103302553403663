import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getUsers, deleteUser, changeStatus } from 'services/users';
import MainCard from 'ui-component/cards/MainCard';
import { IconUser, IconUserPlus } from '@tabler/icons';
import { enqueueSnackbar } from 'notistack';
import { Search } from '@mui/icons-material';
import { useDebouncedCallback } from 'use-debounce';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import { styled } from '@mui/styles';

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#fafafa'
    }
}));

export default function GridUsers() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const page = useSelector((state) => state.users.page);
    const nameUser = useSelector((state) => state.users.nameUser);
    const rowsPerPage = useSelector((state) => state.users.rowsPerPage);

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    function fetchUsers() {
        setLoading(true);
        getUsers(page + 1, rowsPerPage, nameUser)
            .then((resp) => setUsers(resp.data))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const deleteUserById = (id) => {
        setLoading(true);
        deleteUser(id)
            .then(() => {
                fetchUsers();
                enqueueSnackbar('Usuário excluído com sucesso', { variant: 'success' });
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar('Erro ao excluir usuário', { variant: 'error' });
            })
            .finally(() => setLoading(false));
    };

    const changeStatusActive = (user_id) => {
        setLoading(true);
        changeStatus(user_id)
            .then(() => fetchUsers())
            .catch((error) => console.error(error));
    };

    const handleNameUser = (e) => {
        setLoading(true);
        dispatch({ type: 'SET_NAME_USER', payload: e.target.value });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        dispatch({ type: 'SET_PAGE_USER', payload: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        setLoading(true);
        dispatch({ type: 'SET_ROWS_PER_PAGE_USER', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_USER', payload: 0 });
    };

    const fetchUsersDebounced = useDebouncedCallback(fetchUsers, 1000);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            fetchUsers();
            setFirstLoad(false);
            return;
        }
        fetchUsersDebounced();
    }, [nameUser]); // eslint-disable-line

    useEffect(() => {
        if (!firstLoad) fetchUsers();
    }, [page, rowsPerPage]); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUser />
                    <Typography variant="h2" component="div">
                        Usuários
                    </Typography>
                </Stack>
            }
        >
            <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Pesquisar"
                        variant="filled"
                        size="small"
                        InputProps={{
                            endAdornment: <Search />
                        }}
                        value={nameUser}
                        onChange={handleNameUser}
                    />
                </FormControl>
            </Stack>
            <TableContainer component={Paper} sx={{ position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: '#ececec' }}>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Perfil</TableCell>
                            <TableCell width={'140px'}>Status</TableCell>
                            <TableCell width={'200px'}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.data &&
                            users.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <TableCell style={{ color: 'black' }}>{row.name}</TableCell>
                                    <TableCell style={{ color: 'black' }}>{row.email}</TableCell>
                                    <TableCell style={{ color: 'black' }}>{row.perfil}</TableCell>
                                    <TableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.ativo === 1 ? true : false}
                                            onChange={() => changeStatusActive(row.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Visualizar">
                                                <IconButton onClick={() => navigate({ pathname: `/usuario/${row.id}/view` })}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => navigate({ pathname: `/usuario/${row.id}/edit` })}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <ConfirmationDialog
                                                title="Excluir Usuário"
                                                description="Você tem certeza que deseja excluir este usuário?"
                                                response={() => deleteUserById(row.id)}
                                                severity="error"
                                                confirmationText="Sim, prosseguir"
                                            >
                                                {(showDialog) => (
                                                    <Tooltip title="Excluir">
                                                        <IconButton onClick={showDialog}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </ConfirmationDialog>
                                        </Stack>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        {users.data && users.data.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography variant="body1" align="center">
                                        Nenhum registro encontrado
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={5}
                                count={users.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    top: '90%',
                    position: 'fixed',
                    right: 50
                }}
                icon={<SpeedDialIcon />}
            >
                <SpeedDialAction icon={<IconUserPlus />} tooltipTitle={'Novo Usuário'} onClick={() => navigate('/novo_usuario')} />
            </SpeedDial>
        </MainCard>
    );
}
