import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Button,
    FormHelperText,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Stack,
    Typography,
    Divider,
    InputLabel,
    Select,
    CircularProgress
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { getPartnerPerId, postPartner, updatePartner } from 'services/partners';
import { HandshakeOutlined } from '@mui/icons-material';
import { getUsers } from 'services/users';
import { enqueueSnackbar } from 'notistack';

const initialValues = {
    nome_fantasia: '',
    razao_social: '',
    cnpj: '',
    insc_estadual: '',
    insc_municipal: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: '',
    complemento: '',
    telefone: '',
    email: '',
    mei: '',
    cpf_simples: '',
    contribui_icms: '',
    recolhe_pis: '',
    recolhe_cofins: '',
    recolhe_csll: '',
    conta: '',
    tipo_conta: '',
    dados_bancarios: '',
    novo_usuario: 1,
    nome_usuario: '',
    email_usuario: '',
    user_id: ''
};

const validateSchema = Yup.object().shape({
    nome_fantasia: Yup.string().required('Nome Fantasia é obrigatório'),
    razao_social: Yup.string().required('Razão Social é obrigatório'),
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    insc_estadual: Yup.string().required('Inscrição Estadual é obrigatório'),
    insc_municipal: Yup.string().required('Inscrição Municipal é obrigatório'),
    cep: Yup.string().required('CEP é obrigatório'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
    cidade: Yup.string().required('Cidade é obrigatório'),
    estado: Yup.string().required('Estado é obrigatório'),
    numero: Yup.string().required('Número é obrigatório'),
    complemento: Yup.string().required('Complemento é obrigatório'),
    telefone: Yup.string().required('Telefone é obrigatório'),
    email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
    mei: Yup.string().required('Campo "Possui MEI?" é obrigatório'),
    cpf_simples: Yup.string().required('Campo "Optante pelo Simples Nacional" é obrigatório'),
    contribui_icms: Yup.string().required('Campo "Contribuinte de ICMS" é obrigatório'),
    recolhe_pis: Yup.string().required('Campo "Recolhe PIS" é obrigatório'),
    recolhe_cofins: Yup.string().required('Campo "Recolhe COFINS" é obrigatório'),
    recolhe_csll: Yup.string().required('Campo "Recolhe CSLL" é obrigatório'),
    conta: Yup.string().required('Conta é obrigatória'),
    tipo_conta: Yup.string().required('Tipo de Conta é obrigatório'),
    dados_bancarios: Yup.string().required('Dados Bancários é obrigatório')
});

const Partner = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const isDisabled = params.action === 'view' ? true : false;

    const getPartner = async () => {
        await getPartnerPerId(params.id)
            .then((response) => {
                formik.setValues({
                    ...response.data.data,
                    novo_usuario: 0
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchUsers = () => {
        setLoading(true);
        getUsers('', '', '')
            .then((resp) => setUsers(resp.data?.data))
            .finally(() => setLoading(false));
    };

    const handleStorePartner = async (values) => {
        setLoading(true);
        postPartner(values)
            .then(() => {
                enqueueSnackbar('Parceiro cadastrado com sucesso!', { variant: 'success' });
                navigate(-1);
            })
            .catch((error) => {
                console.error(error);
                formik.setErrors(error.response.data.errors);
                enqueueSnackbar('Ocorreu um erro ao cadastrar parceiro', { variant: 'error' });
            })
            .finally(() => setLoading(false));
    };

    const handleUpdatePartner = async (values) => {
        setLoading(true);
        updatePartner(params.id, values)
            .then(() => {
                enqueueSnackbar('Parceiro atualizado com sucesso!', { variant: 'success' });
                navigate(-1);
            })
            .catch((error) => {
                console.error(error);
                formik.setErrors(error.response.data.errors);
                enqueueSnackbar('Ocorreu um erro ao atualizar parceiro', { variant: 'error' });
            })
            .finally(() => setLoading(false));
    };

    const handleConsultarCEP = async (cep, setFieldValue) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            setFieldValue('endereco', data.logradouro);
            setFieldValue('bairro', data.bairro);
            setFieldValue('cidade', data.localidade);
            setFieldValue('estado', data.uf);
        } catch (error) {
            console.error('Erro ao consultar CEP:', error);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values) => {
            values.cep = values.cep.replace(/\D/g, '');
            values.telefone = values.telefone.replace(/\D/g, '');
            values.cnpj = values.cnpj.replace(/\D/g, '');
            if (params.action === 'edit') handleUpdatePartner(values);
            else handleStorePartner(values);
        }
    });

    useEffect(() => {
        if (params.action === 'view' || params.action === 'edit') getPartner(params.id);
    }, [params.id]); // eslint-disable-line

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <HandshakeOutlined />
                    <Typography variant="h2" component="div">
                        {params?.action === 'view'
                            ? 'Visualizar Parceiro'
                            : params?.action === 'edit'
                            ? 'Editar Parceiro'
                            : 'Novo Parceiro'}
                    </Typography>
                </Stack>
            }
            spacing={gridSpacing}
        >
            <Stack sx={{ position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="text"
                                id="nome-fantasia"
                                label="Nome Fantasia"
                                name="nome_fantasia"
                                disabled={isDisabled}
                                {...formik.getFieldProps('nome_fantasia')}
                                error={Boolean(formik.touched.nome_fantasia && formik.errors.nome_fantasia)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="text"
                                id="razao-social"
                                label="Razão Social"
                                name="razao_social"
                                disabled={isDisabled}
                                {...formik.getFieldProps('razao_social')}
                                error={Boolean(formik.touched.razao_social && formik.errors.razao_social)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputMask
                                mask="99.999.999/9999-99"
                                disabled={isDisabled}
                                {...formik.getFieldProps('cnpj')}
                                error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}
                            >
                                {(inputProps) => (
                                    <TextField {...inputProps} fullWidth id="outlined-cnpj" type="text" label="CNPJ" name="cnpj" />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="number"
                                id="insc-estadual"
                                label="Inscrição Estadual"
                                name="insc_estadual"
                                disabled={isDisabled}
                                {...formik.getFieldProps('insc_estadual')}
                                error={Boolean(formik.touched.insc_estadual && formik.errors.insc_estadual)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="insc-municipal"
                                label="Inscrição Municipal"
                                name="insc_municipal"
                                disabled={isDisabled}
                                {...formik.getFieldProps('insc_municipal')}
                                error={Boolean(formik.touched.insc_municipal && formik.errors.insc_municipal)}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }}>
                        <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                            Endereço
                        </Typography>
                    </Divider>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <InputMask
                                mask="99999-999"
                                disabled={isDisabled}
                                {...formik.getFieldProps('cep')}
                                error={Boolean(formik.touched.cep && formik.errors.cep)}
                                onBlur={(e) => handleConsultarCEP(e.target.value, formik.setFieldValue)}
                            >
                                {(inputProps) => <TextField {...inputProps} fullWidth id="cep" type="text" label="CEP" name="cep" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                id="endereco"
                                type="text"
                                label="Logradouro"
                                name="endereco"
                                disabled
                                {...formik.getFieldProps('endereco')}
                                error={Boolean(formik.touched.endereco && formik.errors.endereco)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="text"
                                id="bairro"
                                label="Bairro"
                                name="bairro"
                                disabled
                                {...formik.getFieldProps('bairro')}
                                error={Boolean(formik.touched.bairro && formik.errors.bairro)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="text"
                                id="cidade"
                                label="Cidade"
                                name="cidade"
                                disabled
                                {...formik.getFieldProps('cidade')}
                                error={Boolean(formik.touched.cidade && formik.errors.cidade)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="text"
                                id="estado"
                                label="Estado"
                                name="estado"
                                disabled
                                {...formik.getFieldProps('estado')}
                                error={Boolean(formik.touched.estado && formik.errors.estado)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                type="number"
                                id="numero"
                                label="Número"
                                name="numero"
                                disabled={isDisabled}
                                {...formik.getFieldProps('numero')}
                                error={Boolean(formik.touched.numero && formik.errors.numero)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                type="text"
                                id="complemento"
                                label="Complemento"
                                name="complemento"
                                disabled={isDisabled}
                                {...formik.getFieldProps('complemento')}
                                error={Boolean(formik.touched.complemento && formik.errors.complemento)}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }}>
                        <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                            Contato
                        </Typography>
                    </Divider>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputMask
                                mask="(99) 99999-9999"
                                disabled={isDisabled}
                                {...formik.getFieldProps('telefone')}
                                error={Boolean(formik.touched.telefone && formik.errors.telefone)}
                            >
                                {(inputProps) => (
                                    <TextField {...inputProps} fullWidth type="text" id="telefone" label="Telefone" name="telefone" />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="email"
                                label="E-mail"
                                name="email"
                                disabled={isDisabled}
                                {...formik.getFieldProps('email')}
                                error={Boolean(formik.touched.email && formik.errors.email)}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }}>
                        <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                            Tributação
                        </Typography>
                    </Divider>
                    <Grid container spacing={3} sx={{ px: 1 }}>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Possui MEI?
                                </FormLabel>
                                <RadioGroup row aria-label="possui-mei" name="mei" disabled={isDisabled} {...formik.getFieldProps('mei')}>
                                    <FormControlLabel disabled={isDisabled} value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel disabled={isDisabled} value={0} control={<Radio />} label="Não" />
                                </RadioGroup>
                                {formik.touched.mei && formik.errors.mei && <FormHelperText error>{formik.errors.mei}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Optante pelo simples?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    disabled={isDisabled}
                                    aria-label="cpf-simples"
                                    name="cpf_simples"
                                    {...formik.getFieldProps('cpf_simples')}
                                >
                                    <FormControlLabel disabled={isDisabled} value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel disabled={isDisabled} value={0} control={<Radio />} label="Não" />
                                </RadioGroup>
                                {formik.touched.cpf_simples && formik.errors.cpf_simples && (
                                    <FormHelperText error>{formik.errors.cpf_simples}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Contribuído ICMS?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    disabled={isDisabled}
                                    aria-label="contribui-icms"
                                    name="contribui_icms"
                                    {...formik.getFieldProps('contribui_icms')}
                                >
                                    <FormControlLabel disabled={isDisabled} value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel disabled={isDisabled} value={0} control={<Radio />} label="Não" />
                                </RadioGroup>
                                {formik.touched.contribui_icms && formik.errors.contribui_icms && (
                                    <FormHelperText error>{formik.errors.contribui_icms}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Parceiro responsável pelo recolhimento do PIS?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    disabled={isDisabled}
                                    aria-label="recolhe-pis"
                                    name="recolhe_pis"
                                    {...formik.getFieldProps('recolhe_pis')}
                                >
                                    <FormControlLabel disabled={isDisabled} value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel disabled={isDisabled} value={0} control={<Radio />} label="Não" />
                                </RadioGroup>
                            </FormControl>
                            {formik.touched.recolhe_pis && formik.errors.recolhe_pis && (
                                <FormHelperText error>{formik.errors.recolhe_pis}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Parceiro responsável pelo recolhimento do COFINS?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    disabled={isDisabled}
                                    aria-label="recolhe-cofins"
                                    name="recolhe_cofins"
                                    {...formik.getFieldProps('recolhe_cofins')}
                                >
                                    <FormControlLabel disabled={isDisabled} value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel disabled={isDisabled} value={0} control={<Radio />} label="Não" />
                                </RadioGroup>
                                {formik.touched.recolhe_cofins && formik.errors.recolhe_cofins && (
                                    <FormHelperText error>{formik.errors.recolhe_cofins}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Parceiro responsável pelo recolhimento do CSLL?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    disabled={isDisabled}
                                    aria-label="recolhe_csll"
                                    name="recolhe_csll"
                                    {...formik.getFieldProps('recolhe_csll')}
                                >
                                    <FormControlLabel disabled={isDisabled} value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel disabled={isDisabled} value={0} control={<Radio />} label="Não" />
                                </RadioGroup>
                                {formik.touched.recolhe_csll && formik.errors.recolhe_csll && (
                                    <FormHelperText error>{formik.errors.recolhe_csll}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }}>
                        <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                            Dados Bancários
                        </Typography>
                    </Divider>
                    <Grid container spacing={3} sx={{ px: 1 }}>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Conta
                                </FormLabel>
                                <RadioGroup row disabled={isDisabled} aria-label="conta" name="conta" {...formik.getFieldProps('conta')}>
                                    <FormControlLabel
                                        disabled={isDisabled}
                                        value={'Pessoa Jurídica'}
                                        control={<Radio />}
                                        label="Pessoa Jurídica"
                                    />
                                    <FormControlLabel
                                        disabled={isDisabled}
                                        value={'Pessoa Física'}
                                        control={<Radio />}
                                        label="Pessoa Física"
                                    />
                                </RadioGroup>
                                {formik.touched.conta && formik.errors.conta && (
                                    <FormHelperText error>{formik.errors.conta}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                    Tipo de conta
                                </FormLabel>
                                <RadioGroup
                                    row
                                    disabled={isDisabled}
                                    aria-label="tipo_conta"
                                    name="tipo_conta"
                                    {...formik.getFieldProps('tipo_conta')}
                                >
                                    <FormControlLabel disabled={isDisabled} value={'Corrente'} control={<Radio />} label="Corrente" />
                                    <FormControlLabel disabled={isDisabled} value={'Poupança'} control={<Radio />} label="Poupança" />
                                </RadioGroup>
                                {formik.touched.tipo_conta && formik.errors.tipo_conta && (
                                    <FormHelperText error>{formik.errors.tipo_conta}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                multiline
                                variant="outlined"
                                minRows={4}
                                maxRows={10}
                                id="dados-bancarios"
                                label="Dados bancários"
                                name="dados_bancarios"
                                disabled={isDisabled}
                                {...formik.getFieldProps('dados_bancarios')}
                                error={Boolean(formik.touched.dados_bancarios && formik.errors.dados_bancarios)}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }}>
                        <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                            Usuário responsável
                        </Typography>
                    </Divider>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <RadioGroup row aria-label="novo-usuario" name="novo-usuario" {...formik.getFieldProps('novo_usuario')}>
                                <FormControlLabel value={1} control={<Radio />} label="Novo Usuário" />
                                <FormControlLabel value={0} control={<Radio />} label="Usuário Existente" />
                            </RadioGroup>
                        </Grid>
                        {formik.values.novo_usuario == 1 && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        id="nome_usuario"
                                        label="Nome do responsável"
                                        name="nome_usuario"
                                        disabled={isDisabled}
                                        {...formik.getFieldProps('nome_usuario')}
                                        error={Boolean(formik.touched.nome_usuario && formik.errors.nome_usuario)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        id="email_usuario"
                                        label="E-mail do responsável"
                                        name="email_usuario"
                                        disabled={isDisabled}
                                        {...formik.getFieldProps('email_usuario')}
                                        error={Boolean(formik.touched.email_usuario && formik.errors.email_usuario)}
                                    />
                                </Grid>
                            </>
                        )}
                        {formik.values.novo_usuario == 0 && (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="id_usuario">Usuário responsável</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="id_usuario"
                                        id="id_usuario"
                                        name="id_usuario"
                                        disabled={isDisabled}
                                        {...formik.getFieldProps('user_id')}
                                        error={Boolean(formik.touched.user_id && formik.errors.user_id)}
                                    >
                                        {users.map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
                        <Button variant="outlined" onClick={() => navigate(-1)} size="large">
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" disabled={params.action === 'view' || loading} size="large">
                            {params.action === 'edit' ? 'Salvar' : 'Cadastrar'}
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </MainCard>
    );
};

export default Partner;
