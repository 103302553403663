import { array, func } from 'prop-types';
import React from 'react';
import styles from './drop-zone.module.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Banner = ({ onClick, onDrop }) => {
    const handleDragOver = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        ev.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        onDrop(ev.dataTransfer.files);
    };

    return (
        <div
            className={styles.banner}
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={onClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <span className={styles.banner_text}>Clique aqui para</span>
            <span className={styles.banner_text}>selecionar os arquivos</span>
            <span className={styles.banner_text}>
                <CloudUploadIcon fontSize="large" />
            </span>
            <span className={styles.banner_text}>Você também pode arrastá-los</span>
            <span className={styles.banner_text}>para esta marcação</span>
        </div>
    );
};

Banner.propTypes = {
    onClick: func,
    onDrop: func
};

const DropZone = ({ onChange, accept = ['*'] }) => {
    const inputRef = React.useRef(null);

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleChange = (ev) => {
        onChange(ev.target.files);
    };

    const handleDrop = (files) => {
        onChange(files);
    };

    return (
        <div className={styles.wrapper}>
            <Banner onClick={handleClick} onDrop={handleDrop} />
            <input
                type="file"
                aria-label="add files"
                className={styles.input}
                ref={inputRef}
                multiple="multiple"
                onChange={handleChange}
                accept={accept.join(',')}
            />
        </div>
    );
};

DropZone.propTypes = {
    accept: array,
    onChange: func
};

export { DropZone };
