import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getPartners, deletePartner, changeStatus } from 'services/partners';
import MainCard from 'ui-component/cards/MainCard';
import { HandshakeOutlined, Search } from '@mui/icons-material';
import { CircularProgress, FormControl, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import { useDebouncedCallback } from 'use-debounce';
import { enqueueSnackbar } from 'notistack';
import { IconUsers } from '@tabler/icons';
import PartnerUsers from './PartnerUsers/PartnerUsers';

const StyledTableCell = styled(TableCell)(() => ({}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#fafafa'
    }
}));

const GridPartners = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(false);

    const page = useSelector((state) => state.partners.page);
    const namePartner = useSelector((state) => state.partners.namePartner);
    const rowsPerPage = useSelector((state) => state.partners.rowsPerPage);

    const [usersModal, setUsersModal] = useState(false);
    const [partner, setPartner] = useState({});

    const fetchPartners = () => {
        setLoading(true);
        getPartners(page + 1, rowsPerPage, namePartner)
            .then((resp) => setPartners(resp.data))
            .finally(() => setLoading(false));
    };

    const deletePartnerById = (id) => {
        setLoading(true);
        deletePartner(id)
            .then(() => {
                enqueueSnackbar('Parceiro excluído com sucesso', { variant: 'success' });
                fetchPartners();
            })
            .catch((e) => {
                enqueueSnackbar('Erro ao excluir parceiro', { variant: 'error' });
                setLoading(false);
                console.error(e);
            });
    };

    const changeStatusActive = (partner_id) => {
        setLoading(true);
        changeStatus(partner_id)
            .then(() => fetchPartners())
            .catch((error) => console.error(error));
    };

    const handleNamePartner = (event) => {
        setLoading(true);
        dispatch({ type: 'SET_NAME_PARTNER', payload: event.target.value });
    };

    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_PARTNER', payload: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_PARTNER', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_PARTNER', payload: 0 });
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const formatCNPJ = (cnpj) => {
        const cleaned = ('' + cnpj).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
        if (match) {
            return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
        }
        return cnpj;
    };

    const fetchPartnersDebounced = useDebouncedCallback(fetchPartners, 1000);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            fetchPartners();
            setFirstLoad(false);
            return;
        }
        fetchPartnersDebounced();
    }, [namePartner]); // eslint-disable-line

    useEffect(() => {
        if (!firstLoad) fetchPartners();
    }, [page, rowsPerPage]); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <HandshakeOutlined />
                    <Typography variant="h2" component="div">
                        Parceiros
                    </Typography>
                </Stack>
            }
        >
            <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Pesquisar"
                        variant="filled"
                        size="small"
                        InputProps={{
                            endAdornment: <Search />
                        }}
                        value={namePartner}
                        onChange={handleNamePartner}
                    />
                </FormControl>
            </Stack>
            <TableContainer component={Paper} sx={{ position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Table sx={{ borderRadius: 4 }}>
                    <TableHead sx={{ backgroundColor: '#ECECEC' }}>
                        <TableRow>
                            <StyledTableCell>Nome</StyledTableCell>
                            <StyledTableCell>CNPJ</StyledTableCell>
                            <StyledTableCell>E-mail</StyledTableCell>
                            <StyledTableCell>Telefone</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partners.data &&
                            partners.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.nome_fantasia}</StyledTableCell>
                                    <StyledTableCell>{formatCNPJ(row.cnpj)}</StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{formatPhoneNumber(row.telefone)}</StyledTableCell>
                                    <StyledTableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.ativo === 1 ? true : false}
                                            onChange={() => changeStatusActive(row.id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell width={'200px'}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Visualizar">
                                                <IconButton onClick={() => navigate({ pathname: `/parceiro/${row.id}/view` })}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => navigate({ pathname: `/parceiro/${row.id}/edit` })}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <ConfirmationDialog
                                                title="Excluir Parceiro"
                                                description="Você tem certeza que deseja excluir este parceiro?"
                                                response={() => deletePartnerById(row.id)}
                                                severity="error"
                                                confirmationText="Sim, prosseguir"
                                            >
                                                {(showDialog) => (
                                                    <Tooltip title="Excluir">
                                                        <IconButton onClick={showDialog}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </ConfirmationDialog>
                                            <Tooltip title="Usuários">
                                                <IconButton
                                                    onClick={() => {
                                                        setPartner(row);
                                                        setUsersModal(true);
                                                    }}
                                                >
                                                    <IconUsers />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        {partners.data?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Nenhum registro encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={6}
                                count={partners?.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <PartnerUsers open={usersModal} onClose={() => setUsersModal(false)} partner={partner} />
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    top: '90%',
                    position: 'fixed',
                    right: 50
                }}
                icon={<SpeedDialIcon />}
            >
                <SpeedDialAction icon={<HandshakeOutlined />} tooltipTitle={'Novo Parceiro'} onClick={() => navigate('/novo_parceiro')} />
            </SpeedDial>
        </MainCard>
    );
};

export default GridPartners;
