const securityQuestions = [
    {
        id: 4,
        question: 'O colaborador utiliza algum EPI (Equipamento de Proteção Individual)?',
        documents: [35]
    },
    {
        id: 5,
        question: 'O colaborador trabalhará com edificações?',
        documents: [36, 43]
    },
    {
        id: 6,
        question: 'O colaborador trabalhará com eletricidade?',
        documents: [37]
    },
    {
        id: 7,
        question: 'O colaborador trabalhará com transporte, movimentação, armazenagem e manuseio de materiais?',
        documents: [38]
    },
    {
        id: 8,
        question: 'O colaborador trabalhará com máquinas e equipamentos?',
        documents: [39]
    },
    {
        id: 9,
        question: 'O colaborador trabalhará com caldeiras, vasos de pressão, tubulações e tanques metálicos de armazenamento?',
        documents: [40]
    },
    {
        id: 10,
        question: 'O colaborador trabalhará com fornos?',
        documents: [41]
    },
    {
        id: 11,
        question: 'O colaborador terá atividades com riscos ergonômicos?',
        documents: [42]
    },
    {
        id: 12,
        question: 'O colaborador trabalhará na indústria da construção/canteiro de obras?',
        documents: [43]
    },
    {
        id: 13,
        question: 'O colaborador trabalhará com inflamáveis e combustíveis?',
        documents: [44]
    },
    {
        id: 14,
        question: 'O colaborador trabalhará com limpeza e manutenção em ambiente de saúde?',
        documents: [46]
    },
    {
        id: 15,
        question: 'O colaborador trabalhará em espaço confinado?',
        documents: [47]
    },
    {
        id: 16,
        question: 'O colaborador trabalhará em altura?',
        documents: [48]
    }
];

export const questions = [
    {
        id: 1,
        nome: 'Segurança - NRs',
        questions: securityQuestions
    }
];
