// material-ui
//import LogoHeader from '../components/Images/Logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from '../assets/images/logo-dark.svg';
 * import logo from '../public/logos/logo.svg';
 *
 */

import logo from 'assets/images/logos/logo.svg';
import logoMini from 'assets/images/logos/logo-mini.svg';
import { Stack, Typography } from '@mui/material';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <img src={logoMini} alt="inova" height="30" />
            <Typography variant="h3" color="inherit" color="white">
                JOPE - GED
            </Typography>
        </Stack>
    );
};
const LogoLogin = () => {
    return <img src={logo} alt="inova" width="200" />;
};

export { Logo, LogoLogin };
