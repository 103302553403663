import { Box, Button, CircularProgress, Divider, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { updatePassword } from 'services/users';
import * as Yup from 'yup';

const PasswordUpdate = () => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            password_confirmation: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Campo obrigatório')
        }),
        onSubmit: async (values) => {
            handleUpdatePassword(values);
        }
    });

    const handleUpdatePassword = async (values) => {
        setLoading(true);
        updatePassword(values)
            .then(() => {
                formik.resetForm();
                enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                formik.setErrors(error.response.data.errors);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Box sx={{ my: 3, p: 3, borderRadius: '10px', border: '1px solid #eaeaea', position: 'relative' }}>
            {loading && (
                <Grid
                    container
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: 100,
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                </Grid>
            )}
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="div">
                            Alterar Senha
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Senha"
                                variant="outlined"
                                type="password"
                                value={formik.values.password}
                                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Confirmação"
                                variant="outlined"
                                type="password"
                                value={formik.values.password_confirmation}
                                onChange={(e) => formik.setFieldValue('password_confirmation', e.target.value)}
                                error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-start">
                            <Button variant="contained" type="submit">
                                Alterar Senha
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default PasswordUpdate;
