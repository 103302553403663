import { app } from './Api';

const getVacations = async (contrato_id = '', efetivo_id = '') => {
    return app.get(`ferias/${contrato_id}/${efetivo_id}`);
};

const postVacation = async (data) => {
    return app.post('ferias', data);
};

const updateVacation = async (id, data) => {
    return app.put(`ferias/${id}`, data);
};

const deleteVacation = async (id) => {
    return app.delete(`ferias/${id}`);
};

const disableVacation = async (id) => {
    return app.delete(`ferias/${id}`);
};

export { getVacations, postVacation, updateVacation, deleteVacation, disableVacation };
