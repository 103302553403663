import { useEffect, useState } from 'react';

// material-ui
import Grid from '@mui/material/Grid';

// project imports
import TotalContracts from './TotalContracts';
import TotalPartners from './TotalPartners';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { getDashboard } from 'services/dashboard';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const [isLoading, setLoading] = useState(true);

    const [dashboardData, setDashboardData] = useState(null);

    const getDashboardData = () => {
        setLoading(true);
        getDashboard().then((response) => {
            const { data } = response.data;
            setDashboardData(data);
            setLoading(false);
        });
    };

    useEffect(() => {
        getDashboardData();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TotalPartners isLoading={isLoading} total={dashboardData?.total?.parceiro} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TotalContracts isLoading={isLoading} total={dashboardData?.total?.contrato} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TotalGrowthBarChart isLoading={isLoading} data={dashboardData} />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
