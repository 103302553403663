import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MENU_OPEN } from 'store/actions';
import { getContracts, deleteContract, changeStatus } from 'services/contracts';
import MainCard from 'ui-component/cards/MainCard';
import { IconLicense } from '@tabler/icons';
import { Stack, Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c0c0c0',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));
export default function GridContracts() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const page = useSelector((state) => state.contracts.page);
    const rowsPerPage = useSelector((state) => state.contracts.rowsPerPage);
    const [contracts, setContracts] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [idDestroyContract, setIdDestroyContract] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const { company } = useSelector((state) => state.company);
    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyContract(idDestroy);
        setOpenModal(true);
    };
    const handleCloseModal = () => setOpenModal(false);

    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_CONTRACT', payload: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_CONTRACT', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_CONTRACT', payload: 0 });
    };

    function getAllContracts() {
        getContracts(page, rowsPerPage, '', '', '', company?.id).then((resp) => setContracts(resp.data));
    }
    const deleteContractById = () => {
        handleCloseModal();
        deleteContract(idDestroyContract)
            .then((resp) => {
                getAllContracts();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyContract('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const changeStatusActive = (contract_id) => {
        changeStatus(contract_id)
            .then(() => getAllContracts())
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getAllContracts();
    }, [page, rowsPerPage, company]); // eslint-disable-line

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'usuarios');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'usuarios' });
        }
    }, []); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconLicense />
                    <Typography variant="h2" component="div">
                        Contratos
                    </Typography>
                </Stack>
            }
        >
            <BasicModal
                open={openModal}
                title="Excluir Contrato"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir contrato? Todos documentos relacionados a este contrato serão excluídos."
                onDelete={deleteContractById}
            />
            <TableContainer component={Paper}>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '10px 0px 0px 0px' }}>
                                Número
                            </StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>Parceiro</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>Status</StyledTableCell>
                            <StyledTableCell
                                style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '0px 10px 0px 0px' }}
                                width={200}
                            >
                                Ações
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contracts.data &&
                            contracts.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell style={{ color: 'black' }}>{row.numero}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black' }}>{row.parceiro}</StyledTableCell>
                                    <StyledTableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.ativo === 1 ? true : false}
                                            onChange={() => changeStatusActive(row.id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell style={{ display: 'flex' }}>
                                        <Tooltip title="Visualizar">
                                            <IconButton onClick={() => navigate({ pathname: `/contrato/${row.id}/view` })}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => navigate({ pathname: `/contrato/${row.id}/edit` })}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir">
                                            <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        {contracts.data && contracts.data.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    Nenhum contrato encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={4}
                                count={contracts?.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    top: '90%',
                    position: 'fixed',
                    right: 50,
                    zIndex: theme.zIndex.speedDial
                }}
                icon={<SpeedDialIcon />}
            >
                <SpeedDialAction icon={<IconLicense />} tooltipTitle={'Novo Contrato'} onClick={() => navigate('/novo_contrato')} />
            </SpeedDial>
        </MainCard>
    );
}
