import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { getUsers } from 'services/users';
import CloseIcon from '@mui/icons-material/Close';
import { IconCheck } from '@tabler/icons';
import { postPartnerUser } from 'services/partners';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const AddPartnerUser = ({ open, onClose, partner, onSuccess }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchUsers = () => {
        setLoading(true);
        getUsers('', '', '')
            .then((resp) => setUsers(resp.data?.data))
            .finally(() => setLoading(false));
    };

    const handleAddUser = (values) => {
        setLoading(true);
        postPartnerUser(partner.id, values.user_id)
            .then(() => {
                enqueueSnackbar('Usuário adicionado com sucesso', { variant: 'success' });
                onSuccess();
                onClose();
            })
            .catch((error) => {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                console.error(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            user_id: null
        },
        onSubmit: handleAddUser
    });

    useEffect(() => {
        if (open) {
            fetchUsers();
            formik.setValues({ user_id: null });
        }
    }, [open]); // eslint-disable-line

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '20px'
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant="h4" component="div">
                                    Adicionar Usuário
                                </Typography>
                            }
                            action={
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ p: 2 }}
                        />
                        <CardContent sx={{ py: 1, px: 2, position: 'relative' }}>
                            {loading && (
                                <Grid
                                    container
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 100,
                                        top: 0,
                                        left: 0,
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CircularProgress />
                                </Grid>
                            )}
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="user_id">Usuário</InputLabel>
                                <Select
                                    labelId="user_id"
                                    id="user_id"
                                    name="user_id"
                                    value={formik.values.user_id}
                                    onChange={formik.handleChange}
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="body1">{user.name}</Typography>
                                                <Typography variant="caption">{user.email}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Stack direction="row" display="flex" justifyContent="flex-end" spacing={1}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    endIcon={<IconCheck size={20} />}
                                    //
                                >
                                    Prosseguir
                                </Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </form>
            </Box>
        </Modal>
    );
};

AddPartnerUser.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    partner: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default AddPartnerUser;
