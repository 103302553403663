import { Alert, Box, Button, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { postVacation } from 'services/vacation';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AddVacation = ({ open, onClose, staff }) => {
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setErrors({});
        setStart('');
        setEnd('');
        onClose();
    };

    const handlePostVacation = () => {
        postVacation({
            efetivo_id: staff.id,
            contrato_id: contractSelected,
            inicio: start,
            fim: end
        })
            .then(() => {
                handleClose();
            })
            .catch((e) => {
                const { errors, error } = e.response.data;
                setErrors(errors || { message: error });
            });
    };

    useEffect(() => {
        if (open) {
            setStart(moment().format('YYYY-MM-DD'));
            setEnd(moment().format('YYYY-MM-DD'));
        }
    }, [open]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 4
                }}
            >
                <Typography sx={{ mb: 4 }} variant="h3" component="h3">
                    Adicionar período de férias
                </Typography>
                {errors.message && (
                    <Alert sx={{ mb: 3 }} severity="error">
                        {errors.message}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="start"
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            label="Início"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <Typography sx={{ fontSize: 12, mt: 1 }} color="error">
                            {errors.inicio && errors.inicio[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="end"
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            label="Fim"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <Typography sx={{ fontSize: 12, mt: 1 }} color="error">
                            {errors.fim && errors.fim[0]}
                        </Typography>
                    </Grid>
                </Grid>
                <Stack sx={{ mt: 3 }} direction="row" justifyContent="flex-end" spacing={2}>
                    <Button onClick={handlePostVacation} color="primary" variant="contained">
                        Adicionar
                    </Button>
                    <Button onClick={handleClose} variant="text" sx={{ color: 'gray' }}>
                        Cancelar
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

AddVacation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    staff: PropTypes.object.isRequired
};

export default AddVacation;
