import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import { useDebouncedCallback } from 'use-debounce';
import { terminateStaffContract as terminateContract, getStaffs } from 'services/staffs';
import VacationStaff from './VacationStaff';
import { enqueueSnackbar } from 'notistack';
import { IconCalendarTime, IconEdit, IconUserOff } from '@tabler/icons';
import PropTypes from 'prop-types';

TableCell.defaultProps = { ...TableCell.defaultProps, style: { color: 'black' } };

const TableStaffs = ({ loading, setLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const page = useSelector((state) => state.staffs.page);
    const rowsPerPage = useSelector((state) => state.staffs.rowsPerPage);
    const filter = useSelector((state) => state.staffs.filter);

    const [staffs, setStaffs] = useState([]);
    const [paginate, setPaginate] = useState({});

    const [staffSelected, setStaffSelected] = useState(null);
    const [vacationOpen, setVacationOpen] = useState(false);

    const getAllStaffs = useDebouncedCallback(() => {
        if (!contractSelected) {
            return setLoading(false);
        }

        getStaffs(page, rowsPerPage, filter, contractSelected, partnerSelected, true).then((response) => {
            const { data, total, current_page, per_page } = response.data;
            setStaffs(data);
            setPaginate({ total, current_page, per_page });
            setLoading(false);
        });
    }, 1000);

    const handleFilter = (keyword) => {
        setLoading(true);
        dispatch({ type: 'SET_FILTER_STAFF', payload: keyword });
    };

    const terminateStaffContract = (staff) => {
        if (!contractSelected) {
            return enqueueSnackbar('Selecione um contrato para remover efetivos', { variant: 'error' });
        }
        terminateContract(staff.id, contractSelected)
            .then(() => {
                getAllStaffs();
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(error.response.data?.error || 'Erro ao processar recisão', { variant: 'error' });
            });
    };

    const formatCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    useEffect(() => {
        getAllStaffs();
    }, [contractSelected, page, rowsPerPage, filter]); // eslint-disable-line

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ mb: 1, py: 1 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Pesquisar"
                        variant="filled"
                        size="small"
                        InputProps={{
                            endAdornment: <SearchIcon />
                        }}
                        value={filter}
                        onChange={(e) => handleFilter(e.target.value)}
                    />
                </FormControl>
            </Stack>
            <TableContainer sx={{ borderRadius: '10px', position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Table>
                    <TableHead sx={{ backgroundColor: '#DCDCDC' }}>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>CPF</TableCell>
                            <TableCell>Contrato</TableCell>
                            <TableCell align="center">Férias</TableCell>
                            <TableCell align="center">Rescisão</TableCell>
                            <TableCell align="center">Editar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staffs.map((staff) => (
                            <TableRow key={staff.id}>
                                <TableCell>{staff.nome}</TableCell>
                                <TableCell>{formatCPF(staff.cpf)}</TableCell>
                                <TableCell>
                                    {staff.possui_contrato ? (
                                        <>
                                            {staff.data_recisao ? (
                                                <Chip
                                                    variant="filled"
                                                    color="warning"
                                                    label={'RESCINDIDO'}
                                                    sx={{ fontWeight: '500' }}
                                                    //
                                                />
                                            ) : (
                                                <Chip
                                                    variant="filled"
                                                    sx={{ backgroundColor: '#19AB27', color: 'white', fontWeight: '500' }}
                                                    label={'VIGENTE'}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Chip
                                            variant="filled"
                                            sx={{ backgroundColor: '#4D4D4D', color: 'white', fontWeight: '500' }}
                                            label={'SEM CONTRATO'}
                                            //
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Férias" arrow>
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    setStaffSelected(staff.id);
                                                    setVacationOpen(true);
                                                }}
                                                disabled={!staff.possui_contrato || (user.perfil_id === 2 && staff.data_recisao !== null)}
                                            >
                                                <IconCalendarTime />
                                            </IconButton>
                                        </>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    <ConfirmationDialog
                                        title="Atenção!"
                                        description={`Você deseja rescindir o contrato ${contractSelected?.numero} com o efetivo ${staff.nome}?`}
                                        response={() => terminateStaffContract(staff)}
                                        severity="warning"
                                        confirmationText="Sim, prosseguir"
                                    >
                                        {(showDialog) => (
                                            <Tooltip title="Rescindir contrato" arrow>
                                                <>
                                                    <IconButton
                                                        onClick={showDialog}
                                                        disabled={staff.data_recisao !== null || !staff.possui_contrato}
                                                    >
                                                        <IconUserOff />
                                                    </IconButton>
                                                </>
                                            </Tooltip>
                                        )}
                                    </ConfirmationDialog>
                                </TableCell>
                                <TableCell align="center">
                                    {staff.possui_contrato ? (
                                        <Tooltip title="Editar contrato" arrow>
                                            <>
                                                <IconButton
                                                    onClick={() => navigate({ pathname: `/efetivo/${staff.id}/edit` })}
                                                    disabled={user.perfil_id === 2 && staff.data_recisao !== null}
                                                >
                                                    <IconEdit />
                                                </IconButton>
                                            </>
                                        </Tooltip>
                                    ) : (
                                        <ConfirmationDialog
                                            title="Atenção!"
                                            description={`Você deseja adicionar o efetivo ${staff.nome} ao contrato ${contractSelected?.numero}?`}
                                            response={() => navigate({ pathname: `/efetivo/${staff.id}/edit` })}
                                            severity="primary"
                                            confirmationText="Sim, prosseguir"
                                        >
                                            {(showDialog) => (
                                                <Tooltip title="Editar contrato" arrow>
                                                    <IconButton
                                                        onClick={showDialog}
                                                        disabled={user.perfil_id === 2 && staff.data_recisao !== null}
                                                    >
                                                        <PostAddOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </ConfirmationDialog>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {staffs.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Nenhum efetivo encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={paginate?.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                                labelRowsPerPage="Registros por página"
                                onPageChange={(e, newPage) => dispatch({ type: 'SET_PAGE_STAFF', payload: newPage })}
                                onRowsPerPageChange={(e) => dispatch({ type: 'SET_ROWS_PER_PAGE_STAFF', payload: e.target.value })}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <VacationStaff
                open={vacationOpen}
                onClose={() => {
                    setStaffSelected(0);
                    setVacationOpen(false);
                }}
                staff={staffs?.find((staff) => staff.id === staffSelected) || {}}
            />
        </>
    );
};

TableStaffs.propTypes = {
    loading: PropTypes.bool,
    setLoading: PropTypes.func
};

export default TableStaffs;
