import styled from 'styled-components';

import { Grid, InputLabel } from '@mui/material';

export const FormStyled = styled.form`
    width: 100%;
    padding: 0 1rem;
`;

export const GroupTitle = styled(Grid)`
    display: flex;
    align-items: center;
    background-color: #f0f1f3;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    padding: 15px;
    border: solid 0.5px #3a6647;

    > h5 {
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--color-primary);
    }
`;

export const GroupInputs = styled(Grid)`
    background-color: #fff;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border: solid 0.5px #3a6647;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
`;

export const GroupInputsEmployee = styled(Grid)`
    background-color: #fff;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border: solid 0.5px #3a6647;
    border-top: none;

    &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    &:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
    }
`;

export const GridImg = styled(Grid)`
    background-color: #fff;
    border: solid 0.5px #3a6647;
    border-top: none;
    border-left: none;

    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 0 0 0.5rem 0;
`;

export const Label = styled(InputLabel)`
    font-weight: 600;
    font-size: 16px;
    color: #1c1c1c;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
`;

export const InputGruop = styled(Grid)`
    border: 1px solid #dcdcdc;
    border-radius: 0.5rem;
    margin: 1rem 0rem;
`;

export const PStyled = styled.p`
    margin-bottom: 0.5rem;
`;

export const ButtonBin = styled.button`
    margin-top: 3.8rem;
    background-color: transparent;
    border: none;

    &:hover {
        background-color: #f5f5f5;
    }
`;

export const ButtonsGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    margin-bottom: 1rem;
`;

export const DivAlert = styled.div`
    color: #d32f2f;
    margin-top: 4px;
    margin-left: 14px;
    font-size: 0.75rem;
`;

export const DivFlex = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
`;
