// assets
import { HandshakeOutlined } from '@mui/icons-material';
import { IconUser, IconLicense, IconUsers, IconDeviceDesktopAnalytics } from '@tabler/icons';

const admin = {
    id: 'admin',
    type: 'group',
    titleName: true,
    children: [
        {
            id: 'index',
            title: 'Painel Inicial',
            type: 'item',
            url: '/index',
            icon: IconDeviceDesktopAnalytics,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'usuarios',
            title: 'Usuários',
            type: 'item',
            url: '/usuarios',
            icon: IconUser,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'parceiros',
            title: 'Parceiros',
            type: 'item',
            url: '/parceiros',
            icon: HandshakeOutlined,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'contratos',
            title: 'Contratos',
            type: 'item',
            url: '/contratos',
            icon: IconLicense,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'efetivos',
            title: 'Efetivos',
            type: 'item',
            url: '/efetivos',
            icon: IconUsers,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }
    ]
};

export default admin;
