import { app } from './Api';

const getTypeDocuments = async (group_document_id = '', contrato_id = '', staff_id = '', periodo = '') => {
    return app.get(
        `tipo_documento?grupo_documento_id=${group_document_id}&contrato_id=${contrato_id}&efetivo_id=${staff_id}&periodo=${periodo}`
    );
};

const getDefaultTypeDocuments = async (efetivo = '', mensal = '') => {
    return app.get(`tipo_documento/padrao?efetivo=${efetivo}&mensal=${mensal}`);
};

const getTypeDocumentsContracts = async (group_document_id = '', contrato_id = '', staff_id = '', periodo = '') => {
    return app.get(
        `tipo_documento_contrato?grupo_documento_id=${group_document_id}&contrato_id=${contrato_id}&efetivo_id=${staff_id}&periodo=${periodo}`
    );
};

const postContract = async (data) => {
    return app.post(`contrato`, data);
};

const getContracts = async (page = '', qtdPerPage = '', nome = null, parceiro_id = '', perfil_id = '', empresa_id = '') => {
    return app.get(
        `contrato?page=${page}&qtd_per_page=${qtdPerPage}&nome=${nome}&parceiro_id=${parceiro_id}&perfil_id=${perfil_id}&empresa_id=${empresa_id}`
    );
};

const deleteContract = async (id) => {
    return app.delete(`contrato/${id}`);
};

const changeStatus = async (id, data) => {
    return app.patch(`contrato/${id}/change`, data);
};

const getContractPerId = async (id) => {
    return app.get(`contrato/${id}`);
};

const updateContract = async (id, data) => {
    return app.put(`contrato/${id}`, data);
};

export {
    getTypeDocuments,
    getDefaultTypeDocuments,
    getTypeDocumentsContracts,
    postContract,
    getContracts,
    deleteContract,
    changeStatus,
    getContractPerId,
    updateContract
};
