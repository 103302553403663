import { useState, useEffect } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Select, Stack, Typography, Divider } from '@mui/material';
// assets
import MainCard from 'ui-component/cards/MainCard';
import { getPartners } from 'services/partners';
import { getContracts } from 'services/contracts';
import TableDocuments from './components/TableDocuments';
import { FolderCopyOutlined } from '@mui/icons-material';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const DocsDiversos = () => {
    const [partners, setPartners] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [partnerSelected, setPartnerSelected] = useState('');
    const [contractSelected, setContractSelected] = useState('');

    const getAllPartners = () => {
        getPartners().then((resp) => {
            setPartners(resp.data.data);
            if (resp.data.data.length === 1) setPartnerSelected(resp.data.data[0].id);
        });
    };

    const getAllContracts = () => {
        getContracts('', '', '', partnerSelected, '').then((resp) => {
            setContracts(resp.data.data);
            if (resp.data.data.length === 1) setContractSelected(resp.data.data[0].id);
        });
    };

    useEffect(() => {
        getAllPartners();
    }, []);

    useEffect(() => {
        if (partnerSelected) {
            getAllContracts();
            setContractSelected('');
        }
    }, [partnerSelected]); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <FolderCopyOutlined />
                    <Typography variant="h2" component="div">
                        Parceiro - Documentos diversos
                    </Typography>
                </Stack>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="partner" shrink={true}>
                            Parceiro
                        </InputLabel>
                        <Select
                            labelId="partner"
                            id="partner"
                            value={partnerSelected}
                            label="Parceiro"
                            onChange={(e) => setPartnerSelected(e.target.value)}
                        >
                            {partners.map((partner) => (
                                <MenuItem key={partner.id} value={partner.id}>
                                    {partner.nome_fantasia}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="contract" shrink={true}>
                            Contrato
                        </InputLabel>
                        <Select
                            labelId="contract"
                            id="contract"
                            value={contractSelected}
                            label="Contrato"
                            onChange={(e) => setContractSelected(e.target.value)}
                        >
                            {contracts.map((contract) => (
                                <MenuItem key={contract.id} value={contract.id}>
                                    {contract.numero}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
            <TableDocuments contractSelected={contractSelected || 0} />
        </MainCard>
    );
};

export default DocsDiversos;
