import { Box, Divider, FormHelperText, Modal, Stack, TextField, Typography } from '@mui/material';
import { ButtonsGroupStyled, ButtonStyled } from './styles';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { updateDocument } from 'services/documents';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

const DocumentStatus = ({ onOpen, onClose, document, reloadDocuments }) => {
    const user = useSelector((state) => state.auth.user);

    const handleUpdate = (values) => {
        let formData = {};

        formData.validade = values.expiryDate;
        if (values.status) formData.status = values.status;

        updateDocument(document.id, formData)
            .then(() => {
                reloadDocuments();
                handleClose();
                enqueueSnackbar('Documento atualizado com sucesso!', { variant: 'success' });
            })
            .catch((e) => {
                const { errors } = e.response.data;
                formik.setErrors({ status: errors.status, expiryDate: errors.validade });
                enqueueSnackbar('Erro ao atualizar documento!', { variant: 'error' });
            });
    };

    const handleClose = () => {
        formik.resetForm();
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            status: 'PENDENTE',
            expiryDate: ''
        },
        onSubmit: handleUpdate
    });

    const [editable, setEditable] = useState(false);

    useEffect(() => {
        if (document) {
            if (user.perfil_id === 1 || user.perfil_id === 3) {
                setEditable(true);
            }
            if (user.perfil_id === 2 && document.status != 'VALIDADO') {
                setEditable(true);
            }
        }
    }, [onOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (document) {
            formik.setFieldValue('status', document.status || 'PENDENTE');
            formik.setFieldValue('expiryDate', document.validade || '');
        }
    }, [onOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal open={onOpen} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    borderRadius: '20px'
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Typography id="modal-title" variant="h3" component="h2">
                        Editar Documento
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ mt: 4 }}>
                        <TextField
                            label="Data de Validade"
                            type="date"
                            variant="outlined"
                            value={formik.values.expiryDate}
                            onChange={(e) => formik.setFieldValue('expiryDate', e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={!editable}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{formik.touched.expiryDate && formik.errors.expiryDate}</FormHelperText>
                    </Box>
                    {(user.perfil_id === 1 || user.perfil_id === 3) && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Alterar um status:
                            </Typography>
                            <ButtonsGroupStyled>
                                <ButtonStyled
                                    style={{
                                        ...(formik.values.status === 'PENDENTE'
                                            ? { backgroundColor: '#f44336', color: 'white', border: 'none' }
                                            : { backgroundColor: 'transparent', color: 'inherit', border: '1px solid #f44336' }),
                                        marginRight: '10px'
                                    }}
                                    onClick={() => formik.setFieldValue('status', 'PENDENTE')}
                                >
                                    PENDENTE
                                </ButtonStyled>
                                <ButtonStyled
                                    style={{
                                        ...(formik.values.status === 'A VALIDAR'
                                            ? { backgroundColor: 'orange', color: 'white', border: 'none' }
                                            : { backgroundColor: 'transparent', color: 'inherit', border: '1px solid orange' }),
                                        marginRight: '10px'
                                    }}
                                    onClick={() => formik.setFieldValue('status', 'A VALIDAR')}
                                >
                                    A VALIDAR
                                </ButtonStyled>
                                <ButtonStyled
                                    style={{
                                        ...(formik.values.status === 'VALIDADO'
                                            ? { backgroundColor: '#4caf50', color: 'white', border: 'none' }
                                            : { backgroundColor: 'transparent', color: 'inherit', border: '1px solid #4caf50' }),
                                        marginRight: '10px'
                                    }}
                                    onClick={() => formik.setFieldValue('status', 'VALIDADO')}
                                >
                                    VALIDADO
                                </ButtonStyled>
                            </ButtonsGroupStyled>
                            <FormHelperText sx={{ color: 'red' }}>{formik.touched.status && formik.errors.status}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 4 }}>
                        <Stack display="flex" direction="row" justifyContent="flex-end" spacing={2}>
                            <ButtonStyled onClick={handleClose} color="primary" variant="outlined">
                                Cancelar
                            </ButtonStyled>
                            <ButtonStyled type="submit" color="primary" variant="contained" disabled={!editable}>
                                Salvar
                            </ButtonStyled>
                        </Stack>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

DocumentStatus.propTypes = {
    onOpen: PropTypes.bool,
    onClose: PropTypes.func,
    document: PropTypes.object,
    reloadDocuments: PropTypes.func
};

export default DocumentStatus;
