import { app } from './Api';

const getNotification = async (document_id) => {
    return app.get(`documento/${document_id}/notificacao`);
};

const postNotification = async (data) => {
    return app.post(`documento/notificacao`, data);
};

const updateNotification = async (id, data) => {
    return app.put(`documento/notificacao/${id}`, data);
};

const deleteNotification = async (id) => {
    return app.delete(`documento/notificacao/${id}`);
};

export { getNotification, postNotification, updateNotification, deleteNotification };
