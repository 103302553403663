import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const partnerDocuments = {
    id: 'docs',
    title: 'Parceiro',
    type: 'group',
    children: [
        {
            id: 'docs',
            title: 'Documentos',
            type: 'collapse',
            icon: FolderCopyOutlinedIcon,

            children: [
                {
                    id: 'docs-fiscal',
                    title: 'Habilitação',
                    type: 'item',
                    url: 'documentos-fiscais',
                    isChildren: true
                },
                {
                    id: 'docs-mensais',
                    title: 'Mensais',
                    type: 'item',
                    url: 'documentos-mensais',
                    isChildren: true
                },
                {
                    id: 'docs-diversos',
                    title: 'Diversos',
                    type: 'item',
                    url: 'documentos-diversos',
                    isChildren: true
                }
            ]
        }
    ]
};

export default partnerDocuments;
