import { app } from './Api';

const getPartners = async (page = '', qtdPerPage = '', nome = '') => {
    return app.get(`parceiro?page=${page}&qtd_per_page=${qtdPerPage}&nome=${nome}`);
};
const deletePartner = async (id) => {
    return app.delete(`parceiro/${id}`);
};
const getPartnerPerId = async (id) => {
    return app.get(`parceiro/${id}`);
};
const updatePartner = async (id, data) => {
    return app.put(`parceiro/${id}`, data);
};
const changeStatus = async (id, data) => {
    return app.patch(`parceiro/${id}/change`, data);
};
const postPartner = async (data) => {
    return app.post(`parceiro`, data);
};
const getPartnerUsers = async (id) => {
    return app.get(`parceiro/${id}/usuario`);
};
const postPartnerUser = async (id, user_id) => {
    return app.post(`parceiro/${id}/usuario/${user_id}`);
};
const deletePartnerUser = async (id, user_id) => {
    return app.delete(`parceiro/${id}/usuario/${user_id}`);
};

export {
    getPartners,
    deletePartner,
    getPartnerPerId,
    updatePartner,
    postPartner,
    changeStatus,
    getPartnerUsers,
    postPartnerUser,
    deletePartnerUser
};
