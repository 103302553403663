import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

// UI
import {
    //
    Button,
    Divider,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useMask, format, unformat } from '@react-input/mask';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { gridSpacing } from 'store/constant';

// Services
import StaffDocuments from './StaffDocuments';
import StaffQuestions from './StaffQuestions';
import { getStaffByCpf, getStaffPerId, postStaff, updateStaff, deleteStaffContract as deleteContract } from 'services/staffs';
import { getContractPerId, getDefaultTypeDocuments } from 'services/contracts';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';
import { IconUsers } from '@tabler/icons';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { getPartnerPerId } from 'services/partners';

const FormStaff = () => {
    const params = useParams();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const [cpfExists, setCpfExists] = useState(null);

    const maskCpfOptions = {
        mask: '___.___.___-__',
        replacement: { _: /\d/ }
    };

    const inputCpfRef = useMask(maskCpfOptions);

    const [loading] = useState(false);

    const isEditableName = () => {
        return user?.perfil_id === 1 || cpfExists === null;
    };

    const handleStoreStaff = (values) => {
        postStaff({
            cpf: unformat(values.cpf, maskCpfOptions),
            nome: values.nome,
            contrato_id: contractSelected,
            documentos: values.documents,
            data_inicio: values.data_inicio,
            data_termino: values.data_termino
        })
            .then(() => {
                enqueueSnackbar('Efetivo cadastrado com sucesso!', { variant: 'success' });
                navigate(-1);
            })
            .catch((error) => {
                formik.setErrors(error.response.data.errors);
            });
    };

    const handleUpdateStaff = (id, values) => {
        updateStaff(id, {
            cpf: unformat(values.cpf, maskCpfOptions),
            nome: values.nome,
            contrato_id: contractSelected,
            documentos: values.documents,
            data_inicio: values.data_inicio,
            data_termino: values.data_termino
        })
            .then(() => {
                enqueueSnackbar('Efetivo atualizado com sucesso!', { variant: 'success' });
                navigate(-1);
            })
            .catch((error) => {
                formik.setErrors(error.response.data.errors);
            });
    };

    const [partnerName, setPartnerName] = useState('');
    const [contractNumber, setContractNumber] = useState('');

    const getPartner = () => {
        getPartnerPerId(partnerSelected).then((response) => {
            const { data } = response.data;
            setPartnerName(data.nome_fantasia);
        });
    };

    const getContract = () => {
        getContractPerId(contractSelected).then((response) => {
            const { data } = response.data;
            setContractNumber(data.numero);
        });
    };

    const getStaff = async (id = null) => {
        const response = await getDefaultTypeDocuments('1');
        const defaultDocuments = response.data.map((doc) => doc.id);

        if (!id) return formik.setFieldValue('documents', defaultDocuments);

        getStaffPerId(id).then((response) => {
            const { data: staff } = response.data;

            const data_inicio = staff.contratos?.find((doc) => doc.contrato_id === contractSelected)?.data_inicio;
            const data_termino = staff.contratos?.find((doc) => doc.contrato_id === contractSelected)?.data_termino;
            const documentos = staff.documentos?.find((doc) => doc.contrato_id === contractSelected)?.documentos;

            formik.setFieldValue('cpf', format(staff.cpf, maskCpfOptions));
            formik.setFieldValue('nome', staff.nome);
            formik.setFieldValue('data_inicio', data_inicio || null);
            formik.setFieldValue('data_termino', data_termino || null);
            formik.setFieldValue('documents', documentos !== undefined ? documentos : defaultDocuments);
        });
    };

    const checkCpfExists = (value) => {
        getStaffByCpf(value).then((response) => {
            const { data } = response;
            if (data) {
                getStaff(data.id);
                setCpfExists(data.id);
            } else {
                formik.setFieldValue('nome', '');
                setCpfExists(null);
            }
        });
    };

    const deleteStaffContract = (staff) => {
        deleteContract(staff.id, contractSelected).then(() => {
            navigate(-1);
        });
    };

    const formik = useFormik({
        initialValues: {
            cpf: '',
            nome: '',
            documents: [],
            data_inicio: null,
            data_termino: null
        },
        onSubmit: (values) => {
            if (params.id || cpfExists !== null) handleUpdateStaff(params.id || cpfExists, values);
            else handleStoreStaff(values);
        }
    });

    useEffect(() => {
        setCpfExists(false);
        if (formik.values.cpf.length == 14) checkCpfExists(unformat(formik.values.cpf, maskCpfOptions));
    }, [formik.values.cpf]); // eslint-disable-line

    useEffect(() => {
        getStaff(params?.id);
    }, [params]); // eslint-disable-line

    useEffect(() => {
        if (partnerSelected) getPartner();
    }, [partnerSelected]); // eslint-disable-line

    useEffect(() => {
        if (contractSelected) getContract();
    }, [contractSelected]); // eslint-disable-line

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUsers />
                    <Typography variant="h2" component="div">
                        {params?.action === 'view' ? 'Visualizar Efetivo' : params?.action === 'edit' ? 'Editar Efetivo' : 'Novo Efetivo'}
                    </Typography>
                </Stack>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={12}>
                        {partnerSelected && contractSelected && (
                            <Stack
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                    p: 4,
                                    borderRadius: 2,
                                    width: '100%'
                                }}
                            >
                                <Typography variant="h3">
                                    {partnerName} - {contractNumber}
                                </Typography>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            label="CPF"
                            value={formik.values.cpf}
                            {...formik.getFieldProps('cpf')}
                            disabled={params?.id !== undefined}
                            inputRef={inputCpfRef}
                            error={Boolean(formik.touched?.cpf && formik.errors?.cpf)}
                        />
                        <FormHelperText error>{formik.touched?.cpf && formik.errors?.cpf}</FormHelperText>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            label="Nome"
                            value={formik.values.nome}
                            {...formik.getFieldProps('nome')}
                            disabled={!isEditableName()}
                            error={Boolean(formik.touched?.nome && formik.errors?.nome)}
                        />
                        <FormHelperText error>{formik.touched?.nome && formik.errors?.nome}</FormHelperText>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Vigência do contrato
                </Typography>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                            <DatePicker
                                label="Data Início"
                                value={formik.values.data_inicio}
                                onChange={(value) => formik.setFieldValue('data_inicio', value)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                disabled={loading || params?.action === 'view'}
                                error={Boolean(formik.touched?.data_inicio && formik.errors?.data_inicio)}
                            />
                        </LocalizationProvider>
                        <FormHelperText error>{formik.touched?.data_inicio && formik.errors?.data_inicio}</FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                            <DatePicker
                                label="Data Término"
                                value={formik.values.data_termino}
                                onChange={(value) => formik.setFieldValue('data_termino', value)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                disabled={loading || params?.action === 'view'}
                                error={Boolean(formik.touched?.data_termino && formik.errors?.data_termino)}
                            />
                        </LocalizationProvider>
                        <FormHelperText error>{formik.touched?.data_termino && formik.errors?.data_termino}</FormHelperText>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Documentos Solicitados
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        {(user?.perfil_id == 1 || user?.perfil_id == 3) && (
                            <StaffDocuments
                                documents={formik.values.documents}
                                setDocuments={(documents) => formik.setFieldValue('documents', documents)}
                            />
                        )}
                        {user?.perfil_id == 2 && (
                            <StaffQuestions
                                documents={formik.values.documents}
                                setDocuments={(documents) => formik.setFieldValue('documents', documents)}
                            />
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    {params.id && (user?.perfil_id == 1 || user?.perfil_id == 3) && (
                        <ConfirmationDialog
                            title="Atenção!"
                            description={`Você deseja excluir o contrato ${contractNumber} com o efetivo ${formik.values.nome}?`}
                            response={() => deleteStaffContract({ id: params.id })}
                            severity="error"
                            confirmationText="Sim, prosseguir"
                        >
                            {(showDialog) => (
                                <Button variant="text" color="error" onClick={showDialog}>
                                    Excluir contrato
                                </Button>
                            )}
                        </ConfirmationDialog>
                    )}
                    <Button variant="outlined" onClick={() => navigate(-1)} size="large">
                        Voltar
                    </Button>
                    <Button variant="contained" color="primary" type="submit" size="large">
                        Salvar
                    </Button>
                </Stack>
            </form>
        </MainCard>
    );
};

export default FormStaff;
