import { app } from './Api';

const postUser = async (data) => {
    return app.post(`usuario`, data);
};

const getUsers = async (page = null, qtdPerPage = null, search = null) => {
    return app.get(`usuario?page=${page}&qtd_per_page=${qtdPerPage}&search=${search}`);
};

const deleteUser = async (id) => {
    return app.delete(`usuario/${id}`);
};

const changeStatus = async (id, data) => {
    return app.patch(`usuario/${id}/change`, data);
};

const getUserPerId = async (id) => {
    return app.get(`usuario/${id}`);
};

const updateUser = async (id, data) => {
    return app.put(`usuario/${id}`, data);
};

const updatePassword = async (data) => {
    return app.post(`perfil/alterar-senha`, data);
};

export { getUsers, postUser, deleteUser, changeStatus, getUserPerId, updateUser, updatePassword };
