import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Button,
    FormHelperText,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Divider,
    Stack,
    CircularProgress
} from '@mui/material';
import { DivFlex } from './styles';
import { useFormik } from 'formik';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { getPartners } from 'services/partners';
import { getTypeDocuments, postContract, getContractPerId, updateContract } from 'services/contracts';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';
import { IconLicense } from '@tabler/icons';
import { enqueueSnackbar } from 'notistack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

const Contract = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { company } = useSelector((state) => state.company);

    const [partners, setPartners] = useState([]);
    const [typeDocuments, setTypeDocuments] = useState([]);
    const [ducumentGroups] = useState([
        { id: 1, nome: 'Fiscal' },
        { id: 2, nome: 'Mensal' },
        { id: 11, nome: 'Diversos' }
    ]);

    const [groupDocumentsSelected, setGroupDocumentsSelected] = useState(1);

    const [loading, setLoading] = useState(false);

    const fetchPartners = () => {
        getPartners().then((resp) => setPartners(resp.data.data));
    };

    const fetchDocumentTypes = (id) => {
        setLoading(true);
        getTypeDocuments(id).then((resp) => {
            setTypeDocuments(resp.data);
            setLoading(false);
        });
    };

    const fetchContract = (id) => {
        getContractPerId(id).then((resp) => {
            formik.setValues(resp.data.data);
        });
    };

    const createContract = (values) => {
        values.tipo_documento = [...new Set(values.tipo_documento)];
        postContract(values)
            .then((resp) => {
                enqueueSnackbar(resp.data.success, { variant: 'success' });
                navigate(-1);
            })
            .catch((e) => {
                if (e.response.data.errors) formik.setErrors(e.response.data.errors);
                enqueueSnackbar('Erro ao salvar contrato', { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editContract = (id, values) => {
        values.tipo_documento = [...new Set(values.tipo_documento)];
        updateContract(id, values)
            .then((resp) => {
                enqueueSnackbar(resp.data.success, { variant: 'success' });
                navigate(-1);
            })
            .catch((e) => {
                if (e.response.data.errors) formik.setErrors(e.response.data.errors);
                enqueueSnackbar('Erro ao salvar contrato', { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const formik = useFormik({
        initialValues: {
            ativo: 1,
            empresa_id: company.id,
            parceiro_id: 0,
            numero: '',
            data_inicio: null,
            data_termino: null,
            tipo_documento: []
        },
        onSubmit: (values) => {
            setLoading(true);
            if (params.action === 'edit') {
                editContract(params.id, values);
            } else {
                createContract(values);
            }
        }
    });

    useEffect(() => {
        fetchDocumentTypes(groupDocumentsSelected);
    }, [groupDocumentsSelected]);

    useEffect(() => {
        company?.id && company?.id != formik.values.empresa_id && navigate(-1);
    }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        params.id && fetchContract(params.id);
    }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchPartners();
    }, []);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconLicense />
                    <Typography variant="h2" component="div">
                        {params?.action === 'view'
                            ? 'Visualizar Contrato'
                            : params?.action === 'edit'
                            ? 'Editar Contrato'
                            : 'Novo Contrato'}
                    </Typography>
                </Stack>
            }
            spacing={gridSpacing}
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched?.numero && formik.errors?.numero)}
                            type="text"
                            label="Número contrato"
                            value={formik.values.numero}
                            {...formik.getFieldProps('numero')}
                            disabled={loading || params.action === 'view'}
                        />
                        <FormHelperText error>{formik.touched?.numero && formik.errors?.numero}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="partnerSelectLabel">Parceiro</InputLabel>
                            <Select
                                labelId="partnerSelectLabel"
                                label="Parceiro"
                                value={formik.values.parceiro_id}
                                {...formik.getFieldProps('parceiro_id')}
                                disabled={loading || params.action === 'view'}
                                error={Boolean(formik.touched?.parceiro_id && formik.errors?.parceiro_id)}
                            >
                                {partners?.map((partner, index) => (
                                    <MenuItem key={index} value={partner.id}>
                                        {partner?.nome_fantasia}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText error>{formik.touched?.parceiro_id && formik.errors?.parceiro_id}</FormHelperText>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Vigência do contrato
                </Typography>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                            <DatePicker
                                label="Data Início"
                                value={formik.values.data_inicio}
                                onChange={(value) => formik.setFieldValue('data_inicio', value)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                disabled={loading || params.action === 'view'}
                                error={Boolean(formik.touched?.data_inicio && formik.errors?.data_inicio)}
                            />
                        </LocalizationProvider>
                        <FormHelperText error>{formik.touched?.data_inicio && formik.errors?.data_inicio}</FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                            <DatePicker
                                label="Data Término"
                                value={formik.values.data_termino}
                                onChange={(value) => formik.setFieldValue('data_termino', value)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                disabled={loading || params.action === 'view'}
                                error={Boolean(formik.touched?.data_termino && formik.errors?.data_termino)}
                            />
                        </LocalizationProvider>
                        <FormHelperText error>{formik.touched?.data_termino && formik.errors?.data_termino}</FormHelperText>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Documentação
                </Typography>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="grupo_documento">Tipo de Documento</InputLabel>
                            <Select
                                labelId="grupo_documento"
                                label="Grupo de Documento"
                                value={groupDocumentsSelected}
                                onChange={(e) => setGroupDocumentsSelected(e.target.value)}
                            >
                                {ducumentGroups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={gridSpacing} sx={{ mt: 1, position: 'relative' }}>
                    {loading && (
                        <Grid
                            container
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                zIndex: 100,
                                top: 0,
                                left: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)'
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Grid>
                    )}
                    {typeDocuments.map((document) => (
                        <Grid item xs={12} sm={12} key={document.id}>
                            <DivFlex>
                                <div style={{ display: 'inline-block' }}>
                                    <Toggle
                                        checked={formik.values.tipo_documento?.find((id) => id === document.id) ? true : false}
                                        disabled={loading || params.action === 'view'}
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            const exists = formik.values.tipo_documento?.find((id) => id == document.id);
                                            if (checked) {
                                                if (!exists)
                                                    formik.setFieldValue('tipo_documento', [...formik.values.tipo_documento, document.id]);
                                            } else {
                                                formik.setFieldValue(
                                                    'tipo_documento',
                                                    formik.values.tipo_documento?.filter((id) => id != document.id)
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                    <h3>
                                        {document?.nome}
                                        {document?.especificacoes ? ` (${document?.especificacoes})` : ''}
                                    </h3>
                                </div>
                            </DivFlex>
                        </Grid>
                    ))}
                </Grid>
                <Stack direction="row" justifyContent="flex-end" spacing={gridSpacing} sx={{ mt: 3 }}>
                    <Button variant="outlined" onClick={() => navigate(-1)} disabled={loading} size="large">
                        Voltar
                    </Button>
                    {params.action !== 'view' && (
                        <Button variant="contained" color="primary" type="submit" disabled={loading} size="large">
                            Salvar
                        </Button>
                    )}
                </Stack>
            </form>
        </MainCard>
    );
};

export default Contract;
