import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    Button,
    Collapse,
    Stack,
    Grid,
    CircularProgress
} from '@mui/material';
import { Visibility, AttachFile, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import { getTypeDocumentsContracts } from 'services/contracts';
import ErrorIcon from '@mui/icons-material/Error';
import { getStaffs } from 'services/staffs';
import UploadDocumentFiles from 'components/UploadDocumentFiles';
import DocumentFiles from 'components/DocumentFiles';
import moment from 'moment';
import DocumentStatus from 'components/DocumentStatus';
import { Link } from 'react-router-dom';
import DocumentNotification from 'components/DocumentNotification';
import { IconMessage2, IconUser } from '@tabler/icons';
import CircleErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const TableDocuments = ({ contractSelected, selectedDate }) => {
    const user = useSelector((state) => state.auth.user);

    const [openValidate, setOpenValidate] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const [allDocuments, setAllDocuments] = useState([]);
    const [staffs, setStaffs] = useState([]);

    const [documentSelected, setDocumentSelected] = useState(null);
    const [staffSelected, setStaffSelected] = useState(null);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState(null);

    const [groupDocuments] = useState([{ id: 4, nome: 'Pessoal', icon: <IconUser />, color: '#CEB3FF' }]);

    const [expandedRow, setExpandedRow] = useState({ rowExpanded: null, doc_id: null });

    const [loadingStaff, setLoadingStaff] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(false);

    const handleExpandRow = (rowId, doc_id) => {
        if (expandedRow?.rowExpanded === rowId && expandedRow?.doc_id === doc_id) {
            setExpandedRow({ rowExpanded: null, doc_id: null });
        } else {
            setExpandedRow({ rowExpanded: rowId, doc_id: doc_id });
        }
        getTypesDocuments(doc_id, rowId);
    };

    function getTypesDocuments(doc_id = null, staff_id = null) {
        const formattedDate = selectedDate ? selectedDate.format('yyyy-MM') : '';
        setLoadingDocuments(true);
        getTypeDocumentsContracts(doc_id, contractSelected, staff_id, formattedDate).then((resp) => {
            setAllDocuments(resp.data);
            setLoadingDocuments(false);
        });
    }

    function getAllStaffs() {
        setLoadingStaff(true);
        getStaffs('', '', '', contractSelected, '').then((response) => {
            setStaffs(response.data?.data);
            setLoadingStaff(false);
        });
    }

    const handleClickOpen = (documento) => {
        setDocumentSelected(documento);
        setOpenValidate(true);
    };

    const renderStatus = (status) => {
        switch (status) {
            case 'A VALIDAR':
                return <Chip sx={{ width: 100 }} color="warning" label="A validar" />;
            case 'VALIDADO':
                return <Chip sx={{ width: 100 }} color="success" label="Validado" />;
            case 'PENDENTE':
                return <Chip sx={{ width: 100 }} color="error" label="Pendente" />;
            default:
                return <Chip sx={{ width: 100 }} color="error" label="Pendente" />;
        }
    };

    const checkVacationMonth = (staff) => {
        return staff.ferias?.find((ferias) => {
            return moment(selectedDate).isSame(ferias.inicio, 'month');
        });
    };

    useEffect(() => {
        if (contractSelected) {
            getAllStaffs();
        } else {
            setStaffs([]);
        }
        setExpandedRow({ rowExpanded: null, doc_id: null });
    }, [contractSelected, selectedDate]); // eslint-disable-line

    return (
        <Stack sx={{ pb: 2 }}>
            {staffs.length > 0 ? (
                <>
                    <TableContainer component={Paper} sx={{ position: 'relative' }}>
                        {loadingStaff && (
                            <Grid
                                container
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 100,
                                    top: 0,
                                    left: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Efetivo</TableCell>
                                    <TableCell width={300} align="left">
                                        Documentação
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {staffs?.map((staff) => (
                                    <React.Fragment key={staff.id}>
                                        <TableRow>
                                            <TableCell>{staff.nome}</TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" spacing={1}>
                                                    {groupDocuments.map((group) => {
                                                        if (group.id === 10 && !checkVacationMonth(staff)) return;
                                                        return (
                                                            <Button
                                                                key={group.id}
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    handleExpandRow(staff.id, group.id);
                                                                    setStaffSelected(staff);
                                                                }}
                                                                sx={{
                                                                    width: 100,
                                                                    fontSize: '12px',
                                                                    border: 0,
                                                                    backgroundColor: group.color,
                                                                    color: 'white',
                                                                    ':hover': {
                                                                        border: 0,
                                                                        backgroundColor: group.color,
                                                                        color: 'white'
                                                                    }
                                                                }}
                                                                startIcon={group.icon}
                                                            >
                                                                {group.nome}
                                                            </Button>
                                                        );
                                                    })}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#f5f5f5' }} colSpan={4}>
                                                <Collapse
                                                    in={expandedRow.rowExpanded === staff.id && expandedRow.doc_id !== null}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <TableContainer component={Paper} sx={{ position: 'relative' }}>
                                                        {loadingDocuments && (
                                                            <Grid
                                                                container
                                                                sx={{
                                                                    position: 'absolute',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    zIndex: 100,
                                                                    top: 0,
                                                                    left: 0,
                                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)'
                                                                }}
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <CircularProgress />
                                                            </Grid>
                                                        )}
                                                        <Table sx={{ backgroundColor: '#f5f5f5' }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Documentos</TableCell>
                                                                    <TableCell width={150}>Status</TableCell>
                                                                    <TableCell width={100}>Observações</TableCell>
                                                                    <TableCell width={100}>Visualizar</TableCell>
                                                                    <TableCell width={100}>Anexar</TableCell>
                                                                    <TableCell width={100}>Editar</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {allDocuments?.map((document) => (
                                                                    <TableRow key={document.id}>
                                                                        <TableCell>
                                                                            <Typography variant="p">{document.nome}</Typography>
                                                                            {document.especificacoes && (
                                                                                <Typography variant="subtitle2" color="GrayText">
                                                                                    {document.especificacoes}
                                                                                </Typography>
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>{renderStatus(document?.documento?.status)}</TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                disabled={document.documento === null}
                                                                                onClick={() => {
                                                                                    setTypeDocumentSelected(document);
                                                                                    setDocumentSelected(document.documento);
                                                                                    setOpenNotificationModal(true);
                                                                                }}
                                                                                sx={{ position: 'relative' }}
                                                                            >
                                                                                <IconMessage2 />
                                                                                {document.documento?.novas_notificacoes == 1 && (
                                                                                    <CircleErrorIcon
                                                                                        color="error"
                                                                                        sx={{
                                                                                            position: 'absolute',
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                            fontSize: '15px'
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                disabled={document.documento === null}
                                                                                onClick={() => {
                                                                                    setTypeDocumentSelected(document);
                                                                                    setDocumentSelected(document.documento);
                                                                                    setOpenFilesModal(true);
                                                                                }}
                                                                            >
                                                                                <Visibility />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Box sx={{ display: 'flex', position: 'relative' }}>
                                                                                <IconButton
                                                                                    disabled={
                                                                                        document?.documento?.status == 'VALIDADO' &&
                                                                                        new Date(document?.documento?.validade) > new Date()
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setTypeDocumentSelected(document);
                                                                                        setDocumentSelected(document.documento);
                                                                                        setOpenUploadModal(true);
                                                                                    }}
                                                                                >
                                                                                    <AttachFile />
                                                                                </IconButton>
                                                                                {document.documento != null ? (
                                                                                    <CheckCircleIcon
                                                                                        sx={{
                                                                                            color: 'green',
                                                                                            width: '15px',
                                                                                            marginRight: '5px',
                                                                                            position: 'absolute'
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                disabled={
                                                                                    document.documento === null || user.perfil_id === 2
                                                                                }
                                                                                onClick={() => handleClickOpen(document.documento)}
                                                                            >
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                {allDocuments.length === 0 && (
                                                                    <TableRow>
                                                                        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                                                                            Nenhum documento encontrado
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 3 }}>
                            <Typography variant="subtitle2" color="GrayText">
                                * - A documentação de rescisão pode ser enviada através da página de{' '}
                                <Link to="/documentos-efetivos">documentos de habilitação</Link> após ser realizado a rescisão.
                            </Typography>
                        </Stack>
                    </TableContainer>
                    <DocumentStatus
                        onOpen={openValidate}
                        onClose={() => setOpenValidate(false)}
                        document={documentSelected}
                        reloadDocuments={() => {
                            getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                        }}
                    />
                    <DocumentFiles
                        openModal={openFilesModal}
                        handleCloseModal={() => {
                            setOpenFilesModal(false);
                            setDocumentSelected(null);
                        }}
                        documentSelected={documentSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        reloadDocuments={() => {
                            getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                        }}
                    />
                    <UploadDocumentFiles
                        openModal={openUploadModal}
                        handleCloseModal={() => {
                            setOpenUploadModal(false);
                            setDocumentSelected(null);
                        }}
                        accept={['application/pdf']}
                        contractSelected={contractSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        staffSelected={staffSelected}
                        dateSelected={selectedDate}
                        reloadDocuments={() => {
                            getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                        }}
                    />
                    <DocumentNotification
                        open={openNotificationModal}
                        onClose={() => {
                            setOpenNotificationModal(false);
                            getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                        }}
                        document={documentSelected}
                    />
                </>
            ) : contractSelected === null || contractSelected === '' ? (
                <Typography variant="h4" display="flex" justifyContent="center" alignItems="center" sx={{ py: 2, color: 'GrayText' }}>
                    Selecione um contrato
                    <ErrorIcon color="warning" />
                </Typography>
            ) : (
                <Typography variant="h4" display="flex" justifyContent="center" alignItems="center" sx={{ py: 2, color: 'GrayText' }}>
                    Nenhum efetivo encontrado
                </Typography>
            )}
        </Stack>
    );
};

TableDocuments.propTypes = {
    contractSelected: PropTypes.number,
    selectedDate: PropTypes.object
};

export default TableDocuments;
