export const initialState = {
    page: 0,
    rowsPerPage: 10,
    namePartner: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const partnersReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_PARTNER':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_PARTNER':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAME_PARTNER':
            return {
                ...state,
                namePartner: actions.payload
            };
        case 'SET_CLEAR_PARTNER_FILTER':
            return {
                ...state,
                namePartner: ''
            };
        default:
            return state;
    }
};

export default partnersReducer;
